import React, { memo, ReactNode, useCallback, useRef, useState } from "react";

import { OptionsDropdown } from "./OptionsDropdown";
import { OptionsType, RowData } from "../types";

import { ReactComponent as OptionsIcon } from "../assets/optionsIcon.svg";
import { OptionsButtonStyled } from "./styles";

type TableOptionsProps<T extends RowData> = {
  parentElementId: string;
  item: T;
  options: OptionsType<T>;
};

type TableOptionsComponent = <T extends RowData>(
  props: TableOptionsProps<T>
) => ReactNode;

export const TableOptions: TableOptionsComponent = memo(
  ({ item, options, parentElementId }) => {
    const optionsButtonRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = useCallback(() => {
      setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen]);

    return (
      <>
        <OptionsButtonStyled onClick={toggleDropdown} ref={optionsButtonRef}>
          <OptionsIcon />
        </OptionsButtonStyled>
        {isDropdownOpen && (
          <OptionsDropdown
            parentElementId={parentElementId}
            toggleDropdown={toggleDropdown}
            optionsButtonRef={optionsButtonRef}
            item={item}
            options={options}
          />
        )}
      </>
    );
  }
);
