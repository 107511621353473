import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { GeoJsonType } from "../../pages/MapEditor/mapEditor";
import { setGeojsonType } from "../../store/slices/currentMap";

import {
  ActionButtonContainer,
  ActionButtonStyled,
  BackgroundStyled,
  ContainerStyled,
  ContentStyled,
  HeaderStyled,
  MessageStyled,
  GeoJsonSelectTypeModalStyled,
} from "./styles";

export const GeoJsonSelectTypeModal = memo(() => {
  const dispatch = useDispatch();

  const handleGeojsonTypeSelect = (type: GeoJsonType) => {
    dispatch(setGeojsonType(type));
  };
  return (
    <GeoJsonSelectTypeModalStyled>
      <BackgroundStyled />
      <ContentStyled>
        <ContainerStyled>
          <HeaderStyled>Select GeoJSON Type</HeaderStyled>
          <MessageStyled>
            Please select the type of GeoJSON you are working with.
          </MessageStyled>
        </ContainerStyled>
        <ActionButtonContainer>
          <ActionButtonStyled
            onClick={() => handleGeojsonTypeSelect(GeoJsonType.Semantic)}
          >
            Semantic
          </ActionButtonStyled>
          <ActionButtonStyled
            onClick={() => handleGeojsonTypeSelect(GeoJsonType.Centerline)}
          >
            Centerline
          </ActionButtonStyled>
        </ActionButtonContainer>
      </ContentStyled>
    </GeoJsonSelectTypeModalStyled>
  );
});
