import { createSlice } from "@reduxjs/toolkit";

import { DataStatus } from "../../types";
import { fetchGetPointClouds } from "./actions";

type PointCloudsState = {
  data: Array<PointCloud>;
  status: DataStatus;
  error: string | null;
};

const initialState: PointCloudsState = {
  data: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "pointClouds",
  initialState,
  reducers: {
    resetPointClouds: () => initialState,
    addNewPointCloud: (state, { payload }) => {
      state.data = [payload, ...state.data];
      return state;
    },
    updatePointCloud: (state, { payload }) => {
      state.data = state.data.map((pointCloud) =>
        pointCloud._id === payload._id ? payload : pointCloud
      );
      return state;
    },
    deletePointCloud: (state, { payload }) => {
      state.data = state.data.filter(
        (pointCloud) => pointCloud._id !== payload
      );
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetPointClouds.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetPointClouds.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetPointClouds.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
