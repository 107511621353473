import React from "react";

import { parseDate } from "../utils";
import { TableOptions } from "../TableOptions/TableOptions";
import { MAIN_PAGE_TABLE_WRAPPER } from "..";
import { OptionsType, RowData } from "../types";

import { TableCellStyled, TableRowStyled } from "./styles";

type TableRowProps<T extends RowData> = {
  rowData: T;
  options: OptionsType<T>;
};

type TableRowComponent = <T extends RowData>(
  props: TableRowProps<T>
) => JSX.Element | null;

export const TableRow: TableRowComponent = ({ rowData, options }) => {
  return (
    <TableRowStyled key={rowData._id}>
      <TableCellStyled>{rowData.name}</TableCellStyled>
      <TableCellStyled>{rowData.description}</TableCellStyled>
      <TableCellStyled>{parseDate(rowData.created)}</TableCellStyled>
      <TableCellStyled>
        {parseDate(rowData.updated)}
        <TableOptions
          parentElementId={MAIN_PAGE_TABLE_WRAPPER}
          item={rowData}
          options={options}
        />
      </TableCellStyled>
    </TableRowStyled>
  );
};
