import styled from "styled-components";

export const ModalStyled = styled.div`
  top: 72px;
  left: calc(50vw - 220px);
  z-index: 1999;
  background-color: rgb(23, 28, 41);
  position: absolute;
  color: #ffffff;
`;

export const ModalWrapperContentStyled = styled.div`
  width: 400px;
  height: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 12px;
`;
