import { RefObject, useEffect, MouseEvent, useMemo } from "react";

export function useOnClickOutside(
  ref: RefObject<HTMLDivElement>,
  callback: () => void
) {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (ref.current && !ref.current.contains(e.target as Element)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick as any);

    return () => {
      document.removeEventListener("click", handleClick as any);
    };
  });
}

export const useIsFieldChanged = (
  newFields: Array<unknown>,
  isShouldStartTrackChanges = true
): boolean => {
  const initialFields = useMemo(() => {
    if (!isShouldStartTrackChanges) return "init";

    return newFields;
  }, [isShouldStartTrackChanges]);

  if (initialFields === "init") return false;

  return initialFields.some((value, index) => {
    const newValue = newFields[index];

    if (Array.isArray(value)) {
      return !isArraysEqual(value, newValue as Array<unknown>);
    } else if (isObject(value) && isObject(newValue)) {
      return !isObjectsEqual(value, newValue);
    } else {
      return !isPrimitivesEqual(value, newValue);
    }
  });
};
export const isArraysEqual = (array1: Array<unknown>, array2: Array<unknown>) =>
  JSON.stringify(array1) === JSON.stringify(array2);
export const isPrimitivesEqual = (value: unknown, newValue: unknown) => {
  return value === newValue;
};
export const isObjectsEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isObjectsEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

export const isObject = (object: any) => {
  return object != null && typeof object === "object";
};
