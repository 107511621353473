import { Feature, GeoJsonProperties, Geometry } from "geojson";

interface TestingLane {
  lane_id: number;
  lane_left_boundary_line: number;
  lane_right_boundary_line: number;
  lane_start_line: number;
  lane_termination_line: number;
}

const EMPTY_TESTING_LANE: Omit<TestingLane, "lane_id"> = {
  lane_left_boundary_line: -1,
  lane_right_boundary_line: -1,
  lane_start_line: -1,
  lane_termination_line: -1,
};

const LINE_TYPE_LANE_PROPERTIES = [
  "lane_left_boundary_line",
  "lane_right_boundary_line",
  "lane_start_line",
  "lane_termination_line",
];

const TESTED_LANES = {};

const getIsNotAssociatedError = (featureId: number) =>
  `Line feature ${featureId} is not associated with any lane element`;

const getLaneError = (laneId: number, lineType: string) =>
  `Lane ${laneId} has no associated ${lineType}`;

export const verifySemanticMap = (
  features: Array<Feature<Geometry, GeoJsonProperties>>
) => {
  const lanes: Record<number, TestingLane> = { ...TESTED_LANES };
  const errors: Array<any> = [];

  features.forEach(({ properties, geometry }) => {
    if (properties && geometry.type === "LineString") {
      const { feature_id, feature_info_list } = properties;
      if (
        !feature_info_list ||
        (feature_info_list && feature_info_list.length === 0)
      ) {
        errors.push(getIsNotAssociatedError(feature_id));
      }
      if (feature_info_list && feature_info_list.length >= 1) {
        feature_info_list.forEach((featureInfo: any) => {
          if (featureInfo.lane_association) {
            if (featureInfo.lane_association !== "NULL") {
              if (
                !lanes[featureInfo.lane_association] &&
                featureInfo.line_type
              ) {
                lanes[Number(featureInfo.lane_association)] = {
                  ...EMPTY_TESTING_LANE,
                  lane_id: Number(featureInfo.lane_association),
                };
              }
              if (
                featureInfo.line_type &&
                LINE_TYPE_LANE_PROPERTIES.includes(featureInfo.line_type)
              ) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lanes[featureInfo.lane_association][featureInfo.line_type] =
                  feature_id;
              }
            } else {
              errors.push(getIsNotAssociatedError(feature_id));
            }
          }
        });
      }
    }
  });

  Object.values(lanes).forEach((lane) => {
    if (Object.values(lane).includes(-1)) {
      Object.entries(lane).forEach((laneProp) => {
        if (laneProp[1] === -1) {
          errors.push(getLaneError(lane.lane_id, laneProp[0]));
        }
      });
    }
  });
  return [...errors];
};
