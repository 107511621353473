import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeAnyModal, getModalData } from "../../../store/slices/modals";
import { fetchPatchProject } from "../../../store/slices/projects";
import { getPointCloudsById } from "../../../store/slices/pointClouds/selectors";

import { ModalBackgroundStyled, ModalStyled } from "../styles";
import { ModalWrapper } from "../ModalWrapper";
import { Input } from "../common/Input";
import { useIsFieldChanged } from "../../../utils/hooks";

export const EditProjectModal = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const pointCloudsById = useSelector(getPointCloudsById);

  const { name, description, latitude, longitude, _id, pointCloudId } =
    modalData as ProjectPatch;

  const [nameValue, setNameValue] = useState(name);
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [latitudeValue, setLatitudeValue] = useState(String(latitude));
  const [longitudeValue, setLongitudeValue] = useState(String(longitude));

  const handleCloseAction = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  const editAction = async () => {
    dispatch(
      fetchPatchProject({
        _id,
        name: nameValue,
        description: descriptionValue,
        latitude: Number(latitudeValue),
        longitude: Number(longitudeValue),
        pointCloudId,
      })
    );
    handleCloseAction();
  };

  const isFieldsChanged = useIsFieldChanged([
    nameValue,
    descriptionValue,
    latitudeValue,
    longitudeValue,
  ]);

  const isFormFilled = nameValue && descriptionValue && latitudeValue;

  const isDisabled = !isFieldsChanged || !isFormFilled;

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <ModalWrapper
        successAction={editAction}
        headerText={"Edit project"}
        actionButtonText={"Edit project"}
        isActionButtonDisabled={isDisabled}
      >
        <Input
          isRequired={true}
          value={nameValue}
          labelText="Name"
          additionalInfo="Please enter project name"
          changeAction={setNameValue}
        />
        <Input
          isRequired={true}
          value={descriptionValue}
          labelText="Description"
          additionalInfo="Please enter project description"
          changeAction={setDescriptionValue}
        />
        <Input
          isRequired={true}
          value={latitudeValue}
          labelText="Lat"
          additionalInfo="Please enter latitude"
          changeAction={setLatitudeValue}
        />
        <Input
          isRequired={true}
          value={longitudeValue}
          labelText="Long"
          additionalInfo="Please enter longitude"
          changeAction={setLongitudeValue}
        />
        <Input
          disabled
          value={pointCloudsById[pointCloudId]?.name ?? ""}
          labelText="Point cloud name"
        />
        <Input
          disabled
          value={pointCloudsById[pointCloudId]?.created ?? ""}
          labelText="Point cloud creation date"
        />
      </ModalWrapper>
    </ModalStyled>
  );
};
