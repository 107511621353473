//TODO change after backend part
import { fetchGetLogin } from "./actions";
import userSlice from "./reducers";

// actions
export const { resetUser, setUser } = userSlice.actions;

// async actions
export { fetchGetLogin };

// selectors

// reducer
export default userSlice.reducer;
