import { FeaturesInterface } from "../../models/map-interface";
import { generateUUID } from "../../utils";

export const updateMeasureFeatures = (
  measureFeatures: FeaturesInterface,
  mode: { _clickSequence: any; _currentDistance?: any; _currentTooltips?: any }
) => {
  const currentMeasureFeatures = { ...measureFeatures };
  if (mode && mode._clickSequence) {
    const { _clickSequence, _currentDistance, _currentTooltips } = mode;
    if (mode._clickSequence.length === 1) {
      currentMeasureFeatures.features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: _clickSequence,
        },
        properties: {
          id: generateUUID(),
          type: "measure",
          currentTooltips: _currentTooltips,
          distance: _currentDistance,
          feature_id: 0,
          feature_info_list: [],
        },
      });
    }
    if (mode._clickSequence.length > 1) {
      currentMeasureFeatures.features.slice(-1)[0] = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: _clickSequence,
        },
        properties: {
          id: generateUUID(),
          type: "measure",
          distance: _currentDistance,
          currentTooltips: _currentTooltips,
          feature_id: 0,
          feature_info_list: [],
        },
      };
    }
  }

  return currentMeasureFeatures;
};

export const getMeasureTooltips = (measureFeatures: FeaturesInterface) => {
  const { features } = measureFeatures;
  const currentTooltips: Array<any> = [];
  features.forEach((feature) => {
    if (feature && feature.properties) {
      currentTooltips.push(...feature.properties.currentTooltips);
    }
  });
  return currentTooltips;
};
