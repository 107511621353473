import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import router from "./routes";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "./index.css";

const SENTRY_URL = window._envK8S_.REACT_APP_SENTRY_URL;

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: window._envK8S_.REACT_APP_ENV || "development",
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

reportWebVitals();
