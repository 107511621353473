import React, { Dispatch, SetStateAction, memo } from "react";
import { useDispatch } from "react-redux";

import { toggleModal } from "../../../../store/slices/modals";
import { SelectedTabEnum } from "../../types";

import { ReactComponent as DividerIcon } from "../../assets/dividerIcon.svg";
import { ReactComponent as PolygonIcon } from "../../assets/polygonIcon.svg";
import { ReactComponent as AddIcon } from "../../assets/addIcon.svg";
import {
  HeaderBodyStyled,
  TabsBodyStyled,
  TabCounterStyled,
  TabStyled,
  TabTitleStyled,
  CounterValueStyled,
  AddModalButtonStyled,
} from "./styles";

type HeaderProps = {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<SelectedTabEnum>>;
  buttonTitle: string;
  createModalName: string;
  projectsCount: number;
  pointCloudsCount: number;
};

export const Header: React.FC<HeaderProps> = memo(
  ({
    activeTab,
    setActiveTab,
    buttonTitle,
    createModalName,
    projectsCount,
    pointCloudsCount,
  }) => {
    const dispatch = useDispatch();

    const openAddModal = () => {
      dispatch(
        toggleModal({
          type: createModalName,
        })
      );
    };

    return (
      <HeaderBodyStyled>
        <TabsBodyStyled>
          <TabStyled onClick={() => setActiveTab(SelectedTabEnum.Projects)}>
            <TabTitleStyled active={activeTab === SelectedTabEnum.Projects}>
              Projects
            </TabTitleStyled>
            <TabCounterStyled active={activeTab === SelectedTabEnum.Projects}>
              <PolygonIcon />
              <CounterValueStyled
                active={activeTab === SelectedTabEnum.Projects}
              >
                {projectsCount}
              </CounterValueStyled>
            </TabCounterStyled>
          </TabStyled>
          <DividerIcon />
          <TabStyled onClick={() => setActiveTab(SelectedTabEnum.PointClouds)}>
            <TabTitleStyled active={activeTab === SelectedTabEnum.PointClouds}>
              Point Clouds
            </TabTitleStyled>
            <TabCounterStyled
              active={activeTab === SelectedTabEnum.PointClouds}
            >
              <PolygonIcon />
              <CounterValueStyled
                active={activeTab === SelectedTabEnum.PointClouds}
              >
                {pointCloudsCount}
              </CounterValueStyled>
            </TabCounterStyled>
          </TabStyled>
        </TabsBodyStyled>
        <AddModalButtonStyled onClick={openAddModal}>
          <AddIcon />
          {buttonTitle}
        </AddModalButtonStyled>
      </HeaderBodyStyled>
    );
  }
);
