import styled from "styled-components";
import GoogleLogin from "react-google-login";

import Background from "../../assets/Backgroung.svg";

export const GoogleLoginButtonStyled = styled(GoogleLogin)`
  border-radius: 8px !important;
  border: 1px solid rgba(30, 36, 50, 0.23) !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 44px !important;
  transition: border 0.2s !important;
  gap: 8px !important;
  padding: 8px 24px !important;
  width: 100% !important;
  margin-top: 24px;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;

  svg {
    margin: -8px -12px -4px -8px !important;
    height: 100%;
  }

  div {
    height: 40px;
  }

  &:focus {
    border: 2px solid #8fbdff !important;
  }
`;

export const SubmitStyled = styled.button.attrs({ type: "submit" })`
  color: rgb(255, 255, 255);
  font-family: "Red Hat Display", sans-serif;
  background-color: rgb(76, 136, 255);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  margin-bottom: 8px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  border: 2px solid transparent;
  transition: all 0.2s;

  &:focus {
    border: 2px solid #8fbdff;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }
`;

export const LoginErrMsgStyled = styled.div`
  color: red;
  margin: 0 auto;
  font-size: 14px;
  position: absolute;
  width: 308px;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  bottom: 0;
`;

export const CancelStyled = styled.button`
  padding: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.54);

  &:focus {
    border: 2px solid #8fbdff;
  }

  &:active {
    opacity: 0.5;
  }
`;

export const LoginPageStyled = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("${Background}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
`;

export const FormContainerStyled = styled.form`
  display: flex;
  padding: 56px;
  width: 480px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.16);
  z-index: 1;

  svg {
    margin-bottom: 40px;
  }
`;

export const InputStyled = styled.input.attrs({
  autoCorrect: "off",
  autoCapitalize: "off",
  spellCheck: "false",
})`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(30, 36, 50, 0.23);
  padding: 12px 24px;
  appearance: none;
  font-size: 14px;
  margin-bottom: 8px;
  box-sizing: border-box;
  height: 48px;
  font-size: 16px;
  font-weight: 300px;
  color: #494949;
  line-height: 130%;

  &::placeholder {
    opacity: 0.5;
  }
`;

export const TitleStyled = styled.div`
  font-size: 32px;
  font-weight: 400px;
  color: #494949;
  margin-bottom: 16px;
`;

export const SubmitButtonStyled = styled.button.attrs({ type: "submit" })`
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  border-radius: 8px;
  background: #005ff9;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
  font-size: 16px;
  color: #ffffff;
  border: none;
  margin-bottom: 8px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

//TODO: remove after the Modals refactoring

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Dropdown } from "react-bootstrap";

export const GoogleLoginStyled = styled(GoogleLogin)`
  border-radius: 4px !important;
  border: 2px solid #cfd0d5 !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 40px !important;
  margin-bottom: 8px !important;
  transition: border 0.2s !important;
  width: 100%;

  div {
    margin: 0px !important;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FormGroupInputContainerStyled = styled(Form.Group)`
  margin-bottom: 16px;
`;

export const LoginFormContainerStyled = styled(Form)`
  margin: 0 auto;
  max-width: 320px;
`;

export const LoginContainerStyled = styled.div`
  padding: 60px 0;
`;

export const FormGroupUniqContainerStyled = styled(Form.Group)`
  margin-bottom: $spacer !important;
`;

export const DropdownContainerStyled = styled(Dropdown)`
  margin-bottom: 16px;
`;
