import { fetchGetCurrentMapData } from "./actions";
import { getCurrentMap } from "./selectors";
import currentMapSlice from "./reducers";

// actions
export const { resetCurrentMap, setCurrentMapData, setGeojsonType } =
  currentMapSlice.actions;

// async actions
export { fetchGetCurrentMapData };

// selectors
export { getCurrentMap };

// reducer
export default currentMapSlice.reducer;
