import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ModalWrapper } from "../ModalWrapper";
import { Input } from "../common/Input";
import { closeAnyModal, getModalData } from "../../../store/slices/modals";
import {
  fetchPatchPointCloud,
  fetchPostPointCloud,
} from "../../../store/slices/pointClouds";
import { LabelField } from "../common/LabelField";
import { PlyFileLoaderController } from "../common/PlyFileLoader/PlyFileLoaderController";
import { useIsFieldChanged } from "../../../utils/hooks";

import { ModalBackgroundStyled, ModalStyled } from "../styles";

export const PointCloudModal = memo(() => {
  const modalData = useSelector(getModalData);
  const [name, setName] = useState<string>((modalData?.name as string) ?? "");
  const [description, setDescription] = useState<string>(
    (modalData?.description as string) ?? ""
  );
  const [file, setFile] = useState<File | null>(null);

  const dispatch = useDispatch();

  const handleCloseAction = () => {
    dispatch(closeAnyModal());
  };

  const isFieldsChanged = useIsFieldChanged([
    name,
    description,
    file,
  ]);

  const isFormFilled = modalData
  ? name && description
  : name && description && file;

  const isDisabled = !isFieldsChanged || !isFormFilled;

  const addEditPointCloud = useCallback(async () => {
    if (isDisabled) return;
    dispatch(
      modalData
        ? fetchPatchPointCloud({
            _id: modalData._id as string,
            name,
            description,
          })
        : fetchPostPointCloud({
            name,
            description,
            file,
          })
    );
    handleCloseAction();
  }, [name, description, file]);

  const pointCloudModalText = `${modalData ? "Edit" : "Add"} Point Cloud`;

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <ModalWrapper
        successAction={addEditPointCloud}
        headerText={pointCloudModalText}
        actionButtonText={pointCloudModalText}
        isActionButtonDisabled={isDisabled}
      >
        <Input
          isRequired={true}
          value={name}
          labelText="Name"
          additionalInfo="Please enter point cloud name"
          changeAction={setName}
        />
        <Input
          isRequired={true}
          value={description}
          labelText="Description"
          additionalInfo="Please enter point cloud description"
          changeAction={setDescription}
        />
        {!modalData && (
          <>
            <LabelField isRequired={true} labelText="File" />
            <PlyFileLoaderController setFile={setFile} file={file} />
          </>
        )}
      </ModalWrapper>
    </ModalStyled>
  );
});
