import styled from "styled-components";

export const VerifySemanticMapModalStyled = styled.div`
  width: 480px;
  min-height: 160px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #172a3f;
  box-shadow: 0px 8px 32px rgba(27, 27, 27, 0.08);
  border-radius: 4px;
  color: #ffffff;
  line-height: 140%;
  box-sizing: border-box;
  padding: 16px;
  z-index: 1;
`;

export const HeaderStyled = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-weight: bold;
`;

export const ErrorLineStyled = styled.div`
  width: 100%;
  height: 24px;
  display: flex;

  span {
    font-weight: bold;
    cursor: pointer;
  }
`;

export const LineHeaderStyled = styled.div`
  width: 100%;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  color: rgba(169, 0, 0, 0.9);
  margin-bottom: 4px;
`;

export const ErrorLineHeaderStyled = styled(LineHeaderStyled)`
  color: rgba(169, 0, 0, 0.9);
`;

export const SuccessLineHeaderStyled = styled(LineHeaderStyled)`
  color: rgba(0, 123, 10, 0.9);
  justify-content: center;
  margin: auto;
`;

export const ErrorLineContainerStyled = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  flex-direction: column;
`;
