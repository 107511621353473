import styled from "styled-components";
import SearchIcon from "../../assets/searchIcon.svg";

export const SearchBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: "Readex Pro", sans-serif;
`;
export const SelectedTitleStyled = styled.div`
  color: #1b1b1b;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.32px;
`;

export const SearchStyled = styled.input`
  border: none;
  outline: none;
  width: 320px;
  height: 48px;
  background-color: #f4f4f4;
  color: #606060;
  font-size: 14px;
  font-weight: 300;
  background-image: url(${SearchIcon});
  background-position: 12px 12px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 45px;
`;
