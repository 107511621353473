export type OrderType = 1 | -1;

export function sortByColumn<T extends string, H extends Record<T, any>>(
  tableData: H[],
  columnName: T,
  order: OrderType
) {
  return tableData.sort(function (a, b) {
    return (
      String(a[columnName]).localeCompare(String(b[columnName]), undefined, {
        numeric: true,
      }) * order
    );
  });
}
