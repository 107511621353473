import React, { Dispatch, SetStateAction, memo } from "react";

import { SearchBodyStyled, SelectedTitleStyled, SearchStyled } from "./styles";

type SearchProps = {
  selectedTitle: string;
  searchPlaceholder: string;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
};

export const Search: React.FC<SearchProps> = memo(
  ({ selectedTitle, searchPlaceholder, searchValue, setSearchValue }) => (
    <SearchBodyStyled>
      <SelectedTitleStyled>{selectedTitle}</SelectedTitleStyled>
      <SearchStyled
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        placeholder={searchPlaceholder}
      />
    </SearchBodyStyled>
  )
);
