import React, { memo, FC, useRef, useCallback } from "react";

import { prettifyFileWeight } from "./utils";
import { DragAndDropFileHOC } from "../DragAndDropFileHOC";
import { FileLoaderProps } from "../types";

import { ReactComponent as EditIcon } from "../../../assets/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteIcon.svg";
import {
  FileUpdateBodyStyled,
  FileNameStyled,
  FileInputStyled,
  FileWeightStyled,
  FileInfoStyled,
  ButtonStyled,
  GroupButtonsStyled,
} from "./styles";

export const EditPlyFileLoader: FC<FileLoaderProps> = memo(
  ({ setFile, file }) => {
    const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleEditFile = useCallback(() => {
      fileInputRef.current.click();
    }, []);
    const handlerLoadFile = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setFile(event.target.files?.[0] || null);
      },
      []
    );

    const fileName = file?.name || "unknown file name";
    const fileWeight = file?.size || 0;

    return (
      <DragAndDropFileHOC setFile={setFile}>
        {(isDragActive) => (
          <FileUpdateBodyStyled isDragActive={isDragActive}>
            <FileInfoStyled>
              <FileNameStyled>{fileName}</FileNameStyled>
              <FileWeightStyled>
                {prettifyFileWeight(fileWeight)}
              </FileWeightStyled>
            </FileInfoStyled>
            <GroupButtonsStyled>
              <ButtonStyled onClick={handleEditFile}>
                <FileInputStyled
                  ref={fileInputRef}
                  onChange={handlerLoadFile}
                  type="file"
                  accept=".ply"
                />
                <EditIcon />
              </ButtonStyled>
              {file && (
                <ButtonStyled onClick={() => setFile(null)}>
                  <DeleteIcon />
                </ButtonStyled>
              )}
            </GroupButtonsStyled>
          </FileUpdateBodyStyled>
        )}
      </DragAndDropFileHOC>
    );
  }
);
