import { memo, FC, useCallback } from "react";
import * as MapConst from "../../../../constants/map-constants";
import * as React from "react";
import { generateUUID } from "../../../../utils";
import update from "immutability-helper";
import { toast } from "react-toastify";
import {
  removeControlLine,
  setStopSign,
} from "../../../../store/slices/mapStructs";
import { useDispatch } from "react-redux";
import {
  LineTypeContainerStyled,
  LineTypeDropDownContainerStyled,
  LineTypeTitleStyled,
} from "./styles";
import { LineStringFeatureDropdown } from "../LineStringFeatureDropdown/LineStringFeatureDropdown";

type LineStringLineTypeDropdownProps = {
  feature_info: any;
  feature_index: number;
  feature_info_index: number;
  testFeatures: any;
  updateFeatures: any;
  _updateBoundaryLineOfLane: any;
  setControlLineAction: any;
  mapStructs: any;
};

export const LineStringLineTypeDropdown: FC<LineStringLineTypeDropdownProps> =
  memo(
    ({
      feature_info,
      feature_index,
      feature_info_index,
      testFeatures,
      updateFeatures,
      _updateBoundaryLineOfLane,
      setControlLineAction,
      mapStructs,
    }) => {
      const dispatch = useDispatch();

      const removeControlLineAction = useCallback(
        (params: any) => dispatch(removeControlLine(params)),
        []
      );

      const setStopSignAction = useCallback(
        (params: any) => dispatch(setStopSign(params)),
        []
      );

      const getAvailableLineTypes = (
        feature_index: number,
        curr_value: string
      ) => {
        const available_line_types_set = new Set([
          MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME,
          MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME,
          MapConst.LANE_START_LINE_STRING_NAME,
          MapConst.LANE_TERMINATION_LINE_STRING_NAME,
          MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME,
        ]);
        available_line_types_set.delete(curr_value);
        // handle in-feature conflict
        const temp_feature_info_list =
          testFeatures.features[feature_index].properties?.feature_info_list;
        if (!temp_feature_info_list) {
          return Array.from(available_line_types_set);
        }
        for (let i = 0; i < temp_feature_info_list.length; i++) {
          const curr_feature_info = temp_feature_info_list[i];
          const curr_line_type =
            curr_feature_info[MapConst.LINE_TYPE_STRING_NAME];
          if (
            curr_line_type === MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME ||
            curr_line_type === MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
          ) {
            available_line_types_set.delete(
              MapConst.LANE_START_LINE_STRING_NAME
            );
            available_line_types_set.delete(
              MapConst.LANE_TERMINATION_LINE_STRING_NAME
            );
            available_line_types_set.delete(
              MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
            );
          } else if (
            curr_line_type === MapConst.LANE_START_LINE_STRING_NAME ||
            curr_line_type === MapConst.LANE_TERMINATION_LINE_STRING_NAME ||
            curr_line_type === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
          ) {
            if (
              curr_line_type === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
            ) {
              available_line_types_set.delete(curr_line_type);
            }
            available_line_types_set.delete(
              MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME
            );
            available_line_types_set.delete(
              MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
            );
          }
        }
        const available_line_types = Array.from(available_line_types_set);
        if (curr_value !== MapConst.NULL_STRING_NAME) {
          available_line_types.unshift(MapConst.NULL_STRING_NAME);
        }
        return available_line_types;
      };

      const available_line_types = getAvailableLineTypes(
        feature_index,
        feature_info[MapConst.LINE_TYPE_STRING_NAME]
      );

      const _clearStaleControlLineMapStructElement = (feature_info: {
        [x: string]: string;
      }) => {
        // remove control line struct
        removeControlLineAction(
          Number(feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME])
        );
        // update stop sign association for this control line to sentinel
        const { stopSigns } = mapStructs;
        const affectedStopSignId =
          feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME];

        if (!affectedStopSignId || !stopSigns[Number(affectedStopSignId)]) {
          toast.error(
            `undefined / missing stop sign id for map struct's control line ${
              feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME]
            }`
          );
        } else {
          setStopSignAction({
            ...stopSigns[Number(affectedStopSignId)],
            associated_control_line_id: MapConst.INVALID_ID_NUMBER,
          });
        }
      };

      const _clearStaleLineStringMapStructElement = (feature_info: any) => {
        if (
          feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.NULL_STRING_NAME
        ) {
          return;
        } else if (
          feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
        ) {
          _clearStaleControlLineMapStructElement(feature_info);
        }
      };

      const _addNewControlLineToMapStructs = (controlLineId: number) => {
        setControlLineAction({
          control_line_id: controlLineId,
          associated_lane_id: MapConst.INVALID_ID_NUMBER,
          associated_stop_sign_id: MapConst.INVALID_ID_NUMBER,
        });
      };

      const _addNewLineStringMapStructElement = (
        e: string,
        feature_info: any
      ) => {
        if (e === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
          _addNewControlLineToMapStructs(
            feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME]
          );
        }
      };

      const handleChangeInLineTypeValue = (
        feature_index: any,
        feature_info_index: any,
        e: any
      ) => {
        const temp_feature_info = {
          ...testFeatures.features[feature_index].properties.feature_info_list[
            feature_info_index
          ],
        };
        if (temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] === e) {
          return;
        }

        // remove struct related to previous type of feature info
        _clearStaleLineStringMapStructElement(temp_feature_info);

        // update corresponding map struct of old lane id
        if (
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
            MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME ||
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
            MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME ||
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
            MapConst.LANE_START_LINE_STRING_NAME ||
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
            MapConst.LANE_TERMINATION_LINE_STRING_NAME
        ) {
          _updateBoundaryLineOfLane(
            temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
            temp_feature_info[MapConst.LINE_TYPE_STRING_NAME],
            MapConst.INVALID_ID_NUMBER
          );
        } else if (
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
        ) {
          // nothing to update - control line mapStruct is just completely removed
        }

        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] = e;
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] =
          MapConst.NULL_STRING_NAME;
        temp_feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME] =
          e === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
            ? generateUUID()
            : MapConst.NULL_STRING_NAME;
        temp_feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] =
          MapConst.NULL_STRING_NAME;

        // add new element to map struct
        _addNewLineStringMapStructElement(e, temp_feature_info);

        const result_state = update(testFeatures, {
          features: {
            [feature_index]: {
              properties: {
                feature_info_list: {
                  [feature_info_index]: { $set: temp_feature_info },
                },
              },
            },
          },
        });
        updateFeatures(result_state);
      };

      return (
        <LineTypeContainerStyled
          key={feature_index + "_" + feature_info_index + "_line_type"}
        >
          <LineTypeTitleStyled>
            <b>LINE TYPE</b>
          </LineTypeTitleStyled>
          <LineTypeDropDownContainerStyled>
            <LineStringFeatureDropdown
              listData={available_line_types}
              currentItem={feature_info[MapConst.LINE_TYPE_STRING_NAME]}
              changeItemAction={(lineType) =>
                handleChangeInLineTypeValue(
                  feature_index,
                  feature_info_index,
                  lineType
                )
              }
            />
          </LineTypeDropDownContainerStyled>
        </LineTypeContainerStyled>
      );
    }
  );
