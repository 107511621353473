import React, { useState, useCallback, useRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useOnClickOutside } from "../../../utils/hooks";
import {
  REPORT_MODAL,
  VERSION_MODAL,
  toggleModal,
} from "../../../store/slices/modals";

import { ReactComponent as Warning } from "../assets/warning.svg";
import { ReactComponent as AboutIcon } from "../assets/about.svg";
import { ReactComponent as LogOutIcon } from "../assets/logOut.svg";
import {
  ContextMenuStyled,
  DropdownContentStyled,
  DropdownItemStyled,
  ContextMenuIconStyled,
} from "./styles";

interface DropdownConfigStyles {
  id: string;
  text: string;
  clickAction: () => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  onlyAdmin: boolean;
}

const ContextMenu = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(ref, () => setShowDropdown(false));

  const toggleReportModalAction = useCallback(() => {
    dispatch(toggleModal({ type: REPORT_MODAL }));
    setShowDropdown(false);
  }, []);

  const toggleVersionsModalAction = useCallback(() => {
    dispatch(toggleModal({ type: VERSION_MODAL }));
    setShowDropdown(false);
  }, []);

  const handleLogout = useCallback(() => {
    window.localStorage.removeItem("JWT");
    window.localStorage.removeItem("JWTrefresh");
    setShowDropdown(false);
    navigate("/login");
  }, []);

  const dropdownConfig: Array<DropdownConfigStyles> = [
    {
      id: "send_report",
      text: "Send Report",
      clickAction: toggleReportModalAction,
      Icon: Warning,
      onlyAdmin: true,
    },
    {
      id: "about",
      text: "About",
      clickAction: toggleVersionsModalAction,
      Icon: AboutIcon,
      onlyAdmin: false,
    },
    {
      id: "logout",
      text: "Logout",
      clickAction: handleLogout,
      Icon: LogOutIcon,
      onlyAdmin: false,
    },
  ];

  return (
    <ContextMenuStyled ref={ref}>
      <ContextMenuIconStyled
        data-test-id="menu"
        active={showDropdown}
        onClick={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <DropdownContentStyled>
          {dropdownConfig.map(({ text, clickAction, Icon, id }) => {
            return (
              <DropdownItemStyled
                data-test-id={id}
                key={id}
                onClick={clickAction}
              >
                <Icon />
                {text}
              </DropdownItemStyled>
            );
          })}
        </DropdownContentStyled>
      )}
    </ContextMenuStyled>
  );
});

export default ContextMenu;
