import React from "react";
import styled from "styled-components";

const ToolBoxItemStyled = styled.div`
  flex-basis: 50%;
`;

const buttonBackground = (props: { selected?: boolean }) =>
  props.selected ? "rgba(30, 84, 183, 0.8)" : "rgba(23, 28, 41, 0.8)";

export const Toolbox = styled.div<{ selected?: boolean }>`
  position: absolute;
  top: 72px;
  left: 0px;
  color: #f0f0f0;
  padding: 0 0 8px 0;

  width: 360px;
  height: calc(100% - 72px);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  overflow-y: auto;
  z-index: 999;
  background: ${buttonBackground};
`;

export const ToolboxRow = (props: any) => <div>{props.children}</div>;
export const ToolboxControl = (props: any) => (
  <ToolBoxItemStyled>{props.children}</ToolBoxItemStyled>
);

export const ToolboxButton = styled.button<{ selected?: boolean }>`
  display: block;
  width: 100%;
  border: none;
  color: #f0f0f0;
  background: ${buttonBackground};
  text-align: left;
  font-size: 16px;
  outline: none;
  height: 44px;
  cursor: pointer;

  &:hover {
    background: #276ef1;
  }

  &:disabled {
    color: gray;
    cursor: not-allowed;
  }
`;

export const ToolboxLabel = styled.div<{ selected?: boolean }>`
  padding: 8px;
  display: block;
  width: 100%;
  border: none;
  color: gray;
  background: ${buttonBackground};
  text-align: left;
  font-size: 14px;
  outline: none;
  height: 44px;
`;

const ToolboxCheckboxBodyStyled = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  color: #f0f0f0;
  background: ${buttonBackground};
  padding-left: 8px;
  text-align: left;
  text-transform: capitalize;
  font-size: 16px;
  outline: none;
  height: 44px;
  cursor: pointer;

  &:hover {
    background: #276ef1;
  }
`;

const ToolBoxCheckboxLabelStyled = styled.label`
  width: 100%;
`;

const ToolboxCheckboxInputStyled = styled.input.attrs({
  type: "checkbox",
})`
  margin: 10px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
`;

export const ToolboxCheckbox = (props: any) => (
  <ToolBoxCheckboxLabelStyled>
    <ToolboxCheckboxBodyStyled>
      <ToolboxCheckboxInputStyled {...{ ...props, children: null }} />
      {props.children}
    </ToolboxCheckboxBodyStyled>
  </ToolBoxCheckboxLabelStyled>
);

export const ToolboxSelect = (props: any) => (
  <ToolBoxCheckboxLabelStyled>
    <ToolboxCheckboxBodyStyled>
      <select value={props.value} onChange={props.onChange}>
        {props.options.map((item: string) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </ToolboxCheckboxBodyStyled>
  </ToolBoxCheckboxLabelStyled>
);

export const FeaturePropertiesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: none;
  color: #f0f0f0;
  text-align: left;
  text-transform: capitalize;
  font-size: 12px;
  outline: none;
`;

export const FeaturePropertiesFeatureInfoStyled = styled.div`
  color: #f0f0f0;
  text-align: left;
  text-transform: capitalize;
  font-size: 12px;
  outline: none;
  padding: 0;
`;

export const DeleteFeaturePropertiesStyled = styled.div`
  height: 23px;
  width: 23px;
  margin-right: 12px;
  cursor: pointer;
`;

export const FeaturePropertiesBodyStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 8px;
`;
