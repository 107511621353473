import styled from "styled-components";

interface FileLoaderStyledProps {
  isDragActive: boolean;
}

export const FileUpdateBodyStyled = styled.div<FileLoaderStyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 16px;
  height: 68px;
  background: #f4f4f4;
  border: ${(props) => (props.isDragActive ? "3px dashed #3f7f7d" : "none")};
`;

export const FileInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 361px;
`;

export const FileNameStyled = styled.div`
  color: #1b1b1b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileWeightStyled = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #606060;
`;

export const GroupButtonsStyled = styled.div`
  display: flex;
  gap: 8px;
`;

export const ButtonStyled = styled.div`
  cursor: pointer;
`;

export const FileInputStyled = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;
