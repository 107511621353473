import { createSlice } from "@reduxjs/toolkit";

import { EMPTY_MAP_STRUCTS_NEW } from "../../../constants/map-constants";
import { MapStructs } from "../../../models/map-interface";

const initialState: MapStructs = { ...EMPTY_MAP_STRUCTS_NEW };
const SPEED_LIMIT_DEFAULT_VALUE = 1.2;

export const Slice = createSlice({
  name: "mapStructs",
  initialState,
  reducers: {
    resetMapStructs: () => initialState,
    setMapStructs: (state, action) => {
      state = {
        ...action.payload,
      };
      return state;
    },
    setNewLane: (state, action) => {
      const newLanes = {
        ...state.lanes,
        [action.payload]: {
          lane_id: action.payload,
          left_boundary_line_id: -1,
          right_boundary_line_id: -1,
          start_line_id: -1,
          termination_line_id: -1,
        },
      };
      state = {
        ...state,
        lanes: newLanes,
      };
      return state;
    },
    setLane: (state, action) => {
      const { lane_id } = action.payload;
      state = {
        ...state,
        lanes: { ...state.lanes, [lane_id]: { ...action.payload } },
      };
      return state;
    },
    setStopSign: (state, action) => {
      const { stop_sign_id } = action.payload;
      state.stopSigns[stop_sign_id] = { ...action.payload };
      return state;
    },
    setControlLine: (state, action) => {
      const { control_line_id } = action.payload;
      state.controlLines[control_line_id] = { ...action.payload };
      return state;
    },
    setIntersection: (state, action) => {
      const { intersection_id } = action.payload;
      state.intersections[intersection_id] = { ...action.payload };
      return state;
    },
    setSpeedLimit: (state, action) => {
      const { laneId, speedLimit } = action.payload;
      if (state.lanes[laneId]) {
        state.lanes[laneId].speed_limit = speedLimit;
      }
      return state;
    },
    resetSpeedLimits: (state) => {
      Object.values(state.lanes).forEach(
        (lane) => (lane.speed_limit = SPEED_LIMIT_DEFAULT_VALUE)
      );
      return state;
    },
    removeLane: (state, action) => {
      if (action.payload && state.lanes[action.payload]) {
        delete state.lanes[action.payload];
      }
      return state;
    },
    removeControlLine: (state, action) => {
      if (action.payload && state.controlLines[action.payload]) {
        delete state.controlLines[action.payload];
      }
      return state;
    },
    removeIntersection: (state, action) => {
      if (action.payload && state.intersections[action.payload]) {
        delete state.intersections[action.payload];
      }
      return state;
    },
    removeStopSign: (state, action) => {
      if (action.payload && state.stopSigns[action.payload]) {
        delete state.stopSigns[action.payload];
      }
      return state;
    },
  },
});

export default Slice;
