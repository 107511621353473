import styled from "styled-components";

export const TableHeaderStyled = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e9e9e9;
`;

export const ColumnTitleStyled = styled.div`
  min-width: 115px;
  display: flex;
  gap: 4px;
  padding: 12px 16px;
  flex: 1 0 0;
  color: #1b1b1b;
  font-size: 12px;
`;

export const ReorderStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
