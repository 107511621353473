import styled from "styled-components";

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalBackgroundStyled = styled.div<{ isTransparent?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ isTransparent }) =>
    isTransparent ? "none" : "#253F7366"};
  box-sizing: border-box;
`;

export const ModalDropdownWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  flex: 5;
`;

export const ModalTextStyled = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 8px;
  flex: 1;
`;

export const ModalTextDropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0;
`;
