import { RootState } from "../..";

export const getIsLoading = (state: RootState) => state.appState.isLoading;
export const getExpirationAccessToken = (state: RootState) =>
  state.appState.expirationAccessToken;
export const getExpirationRefreshToken = (state: RootState) =>
  state.appState.expirationRefreshToken;
export const getLogOutDate = (state: RootState) => state.appState.logOutDate;
export const getSaveSemanticMapFlag = (state: RootState) =>
  state.appState.saveSemanticMapFlag;
