import styled from "styled-components";

interface ActiveTabProps {
  active: boolean;
}

export const HeaderBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: "Readex Pro", sans-serif;
`;

export const TabsBodyStyled = styled.div`
  display: flex;
  gap: 16px;
`;

export const TabStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export const TabTitleStyled = styled.div<ActiveTabProps>`
  color: ${(props) => (props.active ? "#1b1b1b" : "#a4a4a4")};
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.32px;
`;

export const TabCounterStyled = styled.div<ActiveTabProps>`
  height: 32px;

  svg {
    path {
      stroke: ${(props) => (props.active ? "#3f7f7d" : "#a4a4a4")};
    }
  }
`;

export const CounterValueStyled = styled.div<ActiveTabProps>`
  position: relative;
  bottom: 25px;
  color: ${(props) => (props.active ? "#3f7f7d" : "#a4a4a4")};
  text-align: center;
  font-size: 12px;
`;

export const AddModalButtonStyled = styled.div`
  display: flex;
  width: 212px;
  padding: 12px 20px;
  align-items: center;
  align-content: center;
  background: #3f7f7d;
  gap: 8px;
  color: #ffffff;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;
