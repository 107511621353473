import React, { Dispatch, SetStateAction } from "react";

import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { HeaderData, OptionsType, OrderOptions, RowData } from "./types";

import { TableWrapperStyled } from "./styles";

export const MAIN_PAGE_TABLE_WRAPPER = "MainPageTableWrapper";

type TableProps<T extends RowData> = {
  headerData: HeaderData;
  orderOptions: OrderOptions;
  setOrderOptions: Dispatch<SetStateAction<OrderOptions>>;
  tableData: Array<T>;
  options: OptionsType<T>;
};

type TableComponent = <T extends RowData>(
  props: TableProps<T>
) => JSX.Element | null;

export const Table: TableComponent = ({
  headerData,
  tableData,
  orderOptions,
  setOrderOptions,
  options,
}) => (
  <TableWrapperStyled id={MAIN_PAGE_TABLE_WRAPPER}>
    <TableHeader
      headerData={headerData}
      orderOptions={orderOptions}
      setOrderOptions={setOrderOptions}
    />
    <TableBody tableData={tableData} options={options} />
  </TableWrapperStyled>
);
