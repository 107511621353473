import { createSlice } from "@reduxjs/toolkit";

import { BASE } from "../../../utils/api";

const initialState: CurrentMap = {
  _id: "",
  latitude: 0,
  longitude: 0,
  pointCloudJson: "",
  semanticMapGeoJson: "",
  centerlineGeoJson: "",
  geojsonType: null,
};

export const Slice = createSlice({
  name: "currentMap",
  initialState,
  reducers: {
    resetCurrentMap: () => initialState,
    setCurrentMapData: (state, action) => {
      state = {
        ...action.payload,
        pointCloudJson: `${BASE}map-projects/${action.payload._id}/point_cloud.json`,
        semanticMapGeoJson: `${BASE}map-projects/${action.payload._id}/semantic_map.geojson`,
        centerlineGeoJson: `${BASE}map-projects/${action.payload._id}/centerline.geojson`,
      };
      return state;
    },
    setGeojsonType: (state, action) => {
      state.geojsonType = action.payload;
    },
  },
});

export default Slice;
