import React, { memo, ReactNode, useMemo } from "react";
import ReactDOM from "react-dom";

import { OptionsType, RowData } from "../types";

import {
  DEFAULT_DROPDOWN_PADDING,
  DropdownElementStyled,
  DropdownStyled,
  HEIGHT_DROPDOWN_ITEM,
} from "./styles";
import { ModalBackgroundStyled } from "../../../../../components/Modals/styles";

const DEFAULT_DROPDOWN_TOP_OFFSET = 20;

type OptionsDropdownProps<T extends RowData> = {
  parentElementId: string;
  item: T;
  optionsButtonRef: React.MutableRefObject<null | HTMLDivElement>;
  toggleDropdown: () => void;
  options: OptionsType<T>;
};

type OptionsDropdownComponent = <T extends RowData>(
  props: OptionsDropdownProps<T>
) => ReactNode;

export const OptionsDropdown: OptionsDropdownComponent = memo(
  ({ toggleDropdown, optionsButtonRef, options, item, parentElementId }) => {
    const dropdownTopOffset = useMemo(() => {
      const heightDropdown =
        options.length * HEIGHT_DROPDOWN_ITEM + DEFAULT_DROPDOWN_PADDING * 2;
      const boundingOptionsButtonRect =
        optionsButtonRef.current?.getBoundingClientRect();
      const availableScreenHeight = window.innerHeight;
      const isNotVisibleElement =
        availableScreenHeight - (boundingOptionsButtonRect?.bottom ?? 0) <
        heightDropdown;
      const dropdownTopOffset =
        (boundingOptionsButtonRect?.top ?? 0) +
        (isNotVisibleElement ? -heightDropdown : DEFAULT_DROPDOWN_TOP_OFFSET);
      return dropdownTopOffset;
    }, [options]);

    const handleClick = (callback: (param: typeof item) => void) => {
      callback(item);
      toggleDropdown();
    };

    return ReactDOM.createPortal(
      <>
        <ModalBackgroundStyled onClick={toggleDropdown} isTransparent={true} />
        <DropdownStyled top={dropdownTopOffset}>
          {options.map(({ name, callback }) => (
            <DropdownElementStyled
              key={name}
              onClick={() => handleClick(callback)}
            >
              {name}
            </DropdownElementStyled>
          ))}
        </DropdownStyled>
      </>,
      document.getElementById(parentElementId) as Element
    );
  }
);
