import { createSlice } from "@reduxjs/toolkit";

type ModalData = any;

interface ModalType {
  type: string | null;
  data: ModalData;
}

const initialState: ModalType = {
  type: null,
  data: null,
};

export const Slice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    toggleModal: (state, action) =>
      state.type !== action.payload.type
        ? { type: action.payload.type, data: action.payload?.data ?? null }
        : { ...initialState },
    closeAnyModal: () => ({ ...initialState }),
  },
});

export default Slice;
