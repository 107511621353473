import styled from "styled-components";

export const GeoJsonSelectTypeModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1200;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentStyled = styled.div`
  width: 344px;
  min-height: 154px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  background: #172a3f;
  box-shadow: 0 8px 32px rgba(27, 27, 27, 0.08);
  border-radius: 4px;
  color: #ffffff;
  line-height: 140%;
  box-sizing: border-box;
  padding: 16px;
  z-index: 1;
`;

export const HeaderStyled = styled.div`
  font-weight: 700;
  width: 100%;
  margin-bottom: 8px;
`;

export const BackgroundStyled = styled.div`
  background-color: rgba(37, 63, 115, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const MessageStyled = styled.div`
  font-weight: 400;
  width: 100%;
  margin-bottom: 16px;
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActionButtonStyled = styled.div`
  color: #55b8b3;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
