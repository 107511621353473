import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { fetchGetLogin } from "../../store/slices/user";

import {
  LoginErrMsgStyled,
  GoogleLoginButtonStyled,
  LoginPageStyled,
  FormContainerStyled,
  InputStyled,
  TitleStyled,
  SubmitButtonStyled,
} from "./styles";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoginDataEmpty, setIsLoginDataEmpty] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const GOOGLE_CLIENT_ID = window._envK8S_?.REACT_APP_GOOGLE_CLIENT_ID;
  const REACT_APP_LOCAL = window._envK8S_?.REACT_APP_LOCAL;

  useEffect(() => {
    if (
      window.localStorage.getItem("JWT") &&
      window.localStorage.getItem("JWTrefresh")
    ) {
      navigate("/");
    } else {
      if (REACT_APP_LOCAL === "true") {
        dispatch(fetchGetLogin({ email: "", password: "" }));
      }
    }
  }, [navigate]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoginDataEmpty(false); // clear out errors on each submit

    if (!email || !password) {
      setIsLoginDataEmpty(true);
      return;
    }

    dispatch(fetchGetLogin({ email, password }));
  };

  const onGoogleLoginSuccess = useCallback(
    async (response: any) => {
      const idToken = response.tokenId;

      const data = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
      };

      const headers = {
        Authorization: idToken,
        "Content-Type": "application/json",
      };
      try {
        const responseAuth = await axios.post(
          "https://prod.cloud.cyngn.net/api/v1/login/GoogleOauth2/",
          data,
          {
            headers,
          }
        );

        if (responseAuth.data.access) {
          window.localStorage.setItem(
            "JWT",
            `Bearer ${responseAuth.data.access}`
          );
          window.localStorage.setItem(
            "JWTrefresh",
            `Bearer ${responseAuth.data.refresh}`
          );
          navigate("/");
        }
      } catch (error) {
        console.log(error);
        const baseError = error as BaseError;

        if (baseError?.response?.data?.detail) {
          toast.error(baseError.response.data.detail);
          return;
        }
        toast.error("Something went wrong with Google Auth");
      }
    },
    [navigate]
  );

  return (
    <LoginPageStyled>
      <FormContainerStyled onSubmit={handleSubmit}>
        <Logo />
        <TitleStyled>Sign In</TitleStyled>
        {isLoginDataEmpty && (
          <LoginErrMsgStyled>Username and password required.</LoginErrMsgStyled>
        )}
        <InputStyled
          type="email"
          name="email"
          onChange={(e: any) => setEmail(e.currentTarget.value.toLowerCase())}
          placeholder="Email"
          value={email}
        />
        <InputStyled
          type="password"
          name="password"
          onChange={(e: any) => setPassword(e.currentTarget.value)}
          placeholder="Password"
          value={password}
        />
        <SubmitButtonStyled>Sign In</SubmitButtonStyled>
        {GOOGLE_CLIENT_ID && (
          <GoogleLoginButtonStyled
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Sign in with Google"
            onSuccess={onGoogleLoginSuccess}
            onFailure={(e: any) => console.log("Google sign in error", e)}
          />
        )}
      </FormContainerStyled>
    </LoginPageStyled>
  );
}
