import styled from "styled-components";

export const MeasureElementsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
`;

export const MeasureElementsHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
`;

export const MeasureElementsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MeasureElementStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #606060;

  span {
    flex: none;
    margin-right: 8px;
  }
`;

export const RemoveBtnStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  color: rgb(169, 0, 0);
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;
