import { createSlice } from "@reduxjs/toolkit";

import { DataStatus } from "../../types";
import { fetchGetProjects } from "./actions";

type ProjectsState = {
  data: Array<Project>;
  status: DataStatus;
  error: string | null;
};

const initialState: ProjectsState = {
  data: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    resetProjects: () => initialState,
    addNewProject: (state, { payload }) => {
      state.data = [payload, ...state.data];
      return state;
    },
    updateProject: (state, { payload }) => {
      state.data = state.data.map((project) =>
        project._id === payload._id ? payload : project
      );
      return state;
    },
    deleteProject: (state, { payload }) => {
      state.data = state.data.filter((project) => project._id !== payload);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetProjects.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetProjects.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetProjects.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
