import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API from "../../../api";
import { setIsLoading } from "../appState";
import { setCurrentMapData } from ".";

export const fetchGetCurrentMapData = createAsyncThunk(
  "currentMap/fetchGetCurrentMapData",
  async (projectId: string, thunkAPI) => {
    try {
      const response = await API.get(`map-projects/${projectId}`);
      const { _id, latitude, longitude, radius } = response.data;
      thunkAPI.dispatch(
        setCurrentMapData({
          _id,
          latitude,
          longitude,
          radius,
        })
      );
      thunkAPI.dispatch(setIsLoading(false));
      return response.data;
    } catch (error) {
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
      return Promise.reject();
    }
  }
);
