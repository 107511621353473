import styled from "styled-components";

export const HeaderStyled = styled.div`
  height: 72px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: #172a3f;
  color: #bbbbbb;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px solid #e9e9e9;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  user-select: none;
  z-index: 1300;
  position: fixed;
  top: 0;
`;

export const LogoContainerStyled = styled.div`
  height: 100%;
  display: flex;
  width: 212px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;

export const ContentContainerStyled = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
`;
