import {
  fetchGetPointClouds,
  fetchDeletePointCloud,
  fetchPatchPointCloud,
  fetchPostPointCloud,
} from "./actions";
import {
  getPointClouds,
  getIsPointCloudsLoaded,
  getIsPointCloudsLoadingError,
} from "./selectors";
import pointCloudsSlice from "./reducers";

// actions
export const {
  resetPointClouds,
  addNewPointCloud,
  updatePointCloud,
  deletePointCloud,
} = pointCloudsSlice.actions;

// async actions
export {
  fetchGetPointClouds,
  fetchDeletePointCloud,
  fetchPatchPointCloud,
  fetchPostPointCloud,
};

// selectors
export { getPointClouds, getIsPointCloudsLoaded, getIsPointCloudsLoadingError };

// reducer
export default pointCloudsSlice.reducer;
