import styled from "styled-components";

export const DEFAULT_DROPDOWN_PADDING = 8;
export const HEIGHT_DROPDOWN_ITEM = 44;

export const OptionsButtonStyled = styled.button`
  background: none;
  border: none;
  padding: 0;
`;

export const DropdownStyled = styled.div<{
  top: number;
}>`
  position: fixed;
  border: 1px solid #ddd;
  padding: ${DEFAULT_DROPDOWN_PADDING}px 0;
  top: ${({ top }) => `${top}px`};
  right: 50px;
  background-color: #fff;
`;

export const DropdownElementStyled = styled.div`
  align-items: center;
  height: ${HEIGHT_DROPDOWN_ITEM}px;
  font-family: "Readex Pro", sans-serif;
  font-size: 16px;
  min-width: 225px;
  display: flex;
  padding: 8px;
  color: #828282;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;
