export enum SelectedTabEnum {
  Projects = "Projects",
  PointClouds = "PointClouds",
}

type SelectedTabInfo = {
  buttonTitle: string;
  selectedTitle: string;
  searchPlaceholder: string;
  createModalName: string;
};

export type MainPageInfo = {
  [key in keyof typeof SelectedTabEnum]: SelectedTabInfo;
};
