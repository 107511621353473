import ModalsSlice from "./reducers";
import {
  getIsModalOpen,
  getModalData,
  getIsSaveProgressModal,
  getIsReportModal,
  getIsVersionModal,
  getIsConfirmActionModal,
  getIsNewProjectModal,
  getIsEditProjectModal,
  getIsPointCloudModal,
  getIsGeoJsonSelectTypeModal,
  getIsVerifySemanticMapModal,
} from "./selectors";
import {
  SAVE_PROGRESS_MODAL,
  REPORT_MODAL,
  VERSION_MODAL,
  CONFIRM_ACTION_MODAL,
  VERIFY_GEOJSON_MODAL,
} from "./constants";

//constants
export {
  SAVE_PROGRESS_MODAL,
  REPORT_MODAL,
  VERSION_MODAL,
  CONFIRM_ACTION_MODAL,
  VERIFY_GEOJSON_MODAL,
};

// actions
export const { toggleModal, closeAnyModal } = ModalsSlice.actions;

// selectors
export {
  getIsModalOpen,
  getModalData,
  getIsSaveProgressModal,
  getIsReportModal,
  getIsVersionModal,
  getIsConfirmActionModal,
  getIsNewProjectModal,
  getIsEditProjectModal,
  getIsPointCloudModal,
  getIsGeoJsonSelectTypeModal,
  getIsVerifySemanticMapModal,
};

// reducer
export default ModalsSlice.reducer;
