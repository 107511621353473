import React, { Dispatch, SetStateAction, memo, useCallback } from "react";

import { HeaderData, OrderOptions } from "../types";

import { ReactComponent as ReorderIcon } from "../assets/reorderIcon.svg";
import { TableHeaderStyled, ColumnTitleStyled, ReorderStyled } from "./styles";

type TableHeaderProps = {
  headerData: HeaderData;
  orderOptions: OrderOptions;
  setOrderOptions: Dispatch<SetStateAction<OrderOptions>>;
};

export const TableHeader: React.FC<TableHeaderProps> = memo(
  ({ orderOptions, setOrderOptions, headerData }) => {
    const handleOrderOptions = useCallback(
      (columnName: string) => {
        orderOptions.columnName === columnName
          ? setOrderOptions({
              columnName,
              order: orderOptions.order === 1 ? -1 : 1,
            })
          : setOrderOptions({ columnName, order: 1 });
      },
      [orderOptions]
    );

    return (
      <TableHeaderStyled>
        {Object.entries(headerData).map(([key, columnName]) => (
          <ColumnTitleStyled key={key}>
            {columnName}
            <ReorderStyled onClick={() => handleOrderOptions(key)}>
              <ReorderIcon />
            </ReorderStyled>
          </ColumnTitleStyled>
        ))}
      </TableHeaderStyled>
    );
  }
);
