import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { DataStatus } from "../../types";
import { arrayToObjectByField } from "../../../utils";

export const getPointClouds = (state: RootState): PointCloud[] =>
  state.pointClouds.data;
const getPointCloudsStatus = (state: RootState) => state.pointClouds.status;

export const getIsPointCloudsLoaded = createSelector(
  getPointCloudsStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsPointCloudsLoadingError = createSelector(
  getPointCloudsStatus,
  (status) => status === DataStatus.failed
);
export const getPointCloudsById = createSelector(
  getPointClouds,
  (pointClouds) => arrayToObjectByField(pointClouds, "_id")
);
