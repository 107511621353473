import React from "react";

import { TableRow } from "../TableRow";
import { OptionsType, RowData } from "../types";

import { TableBodyStyled } from "./styles";

type TableBodyProps<T extends RowData> = {
  tableData: Array<T>;
  options: OptionsType<T>;
};

type TableBodyComponent = <T extends RowData>(
  props: TableBodyProps<T>
) => JSX.Element | null;

export const TableBody: TableBodyComponent = ({ tableData, options }) => (
  <TableBodyStyled>
    {tableData.map((rowData) => (
      <TableRow key={rowData._id} rowData={rowData} options={options} />
    ))}
  </TableBodyStyled>
);
