import styled from "styled-components";

export const TableRowStyled = styled.div`
  display: flex;
  border-bottom: 1px solid #e9e9e9;
`;
export const TableCellStyled = styled.div`
  overflow: hidden;
  min-width: 115px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 14px 16px;
  flex: 1 0 0;
  color: #1b1b1b;
  font-size: 14px;
`;
