import styled, { keyframes } from "styled-components";

const SpinnerAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const SpinnerStyled = styled.div`
  border: 4px rgba(37, 63, 115, 0.25) solid;
  border-top: 4px rgb(37, 63, 115) solid;
  border-radius: 50%;
  -webkit-animation: ${SpinnerAnimation} 0.6s infinite linear;
  animation: ${SpinnerAnimation} 0.6s infinite linear;
  height: 60px;
  width: 60px;
  z-index: 3001;
`;

export const SpinnerContainerStyled = styled.div`
  z-index: 3000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(37, 63, 115, 0.4);
`;
