import { FC, memo } from "react";
import * as MapConst from "../../../../constants/map-constants";
import * as React from "react";
import { generateUUID } from "../../../../utils";
import update from "immutability-helper";
import { LineStringFeatureDropdown } from "../LineStringFeatureDropdown/LineStringFeatureDropdown";
import {
  LaneAssocContainerStyled,
  LaneAssocDropDownContainerStyled,
  LaneAssocTitleStyled,
} from "./styles";

type LaneAssocDropdownProps = {
  feature_index: number;
  feature_info_index: number;
  testFeatures: any;
  _updateBoundaryLineOfLane: any;
  mapStructs: any;
  setControlLineAction: any;
  updateFeatures: any;
  feature_info: any;
  availableIds: any;
};

export const LaneAssocDropdown: FC<LaneAssocDropdownProps> = memo(
  ({
    feature_index,
    feature_info_index,
    testFeatures,
    _updateBoundaryLineOfLane,
    mapStructs,
    setControlLineAction,
    updateFeatures,
    feature_info,
    availableIds,
  }) => {
    const getLanesOccupiedByBoundaryLineType = (
      feature_index: number,
      boundary_line_type: string
    ) => {
      const occupied_lane_ids = new Set();
      const temp_feature_info_list =
        testFeatures.features[feature_index].properties.feature_info_list;
      for (let i = 0; i < temp_feature_info_list.length; i++) {
        const curr_feature_info = temp_feature_info_list[i];
        const curr_boundary_line_type =
          curr_feature_info[MapConst.LINE_TYPE_STRING_NAME];
        if (
          curr_boundary_line_type === boundary_line_type &&
          curr_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
            MapConst.NULL_STRING_NAME
        ) {
          occupied_lane_ids.add(
            parseInt(
              curr_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
              10
            )
          );
        }
      }
      return occupied_lane_ids;
    };

    const getAvailableLaneIdsForLaneBoundaryLine = (
      feature_index: number,
      feature_info_index: number
    ) => {
      const temp_feature_info =
        testFeatures.features[feature_index].properties.feature_info_list[
          feature_info_index
        ];
      const line_type = temp_feature_info[MapConst.LINE_TYPE_STRING_NAME];
      let available_lane_ids_set;
      let restricted_lane_ids_set: Set<unknown>;

      if (line_type === MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME) {
        available_lane_ids_set = availableIds.laneIdsForLeftLine;
        restricted_lane_ids_set = getLanesOccupiedByBoundaryLineType(
          feature_index,
          MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
        );
      } else if (line_type === MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME) {
        available_lane_ids_set = availableIds.laneIdsForRightLine;
        restricted_lane_ids_set = getLanesOccupiedByBoundaryLineType(
          feature_index,
          MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME
        );
      } else if (line_type === MapConst.LANE_START_LINE_STRING_NAME) {
        available_lane_ids_set = availableIds.laneIdsForStartLine;
        restricted_lane_ids_set = getLanesOccupiedByBoundaryLineType(
          feature_index,
          MapConst.LANE_TERMINATION_LINE_STRING_NAME
        );
      } else if (line_type === MapConst.LANE_TERMINATION_LINE_STRING_NAME) {
        available_lane_ids_set = availableIds.laneIdsForTerminationLine;
        restricted_lane_ids_set = getLanesOccupiedByBoundaryLineType(
          feature_index,
          MapConst.LANE_START_LINE_STRING_NAME
        );
      }
      // DO NOT modify available_lane_ids_set directly
      const available_lane_ids: Array<any> = [];

      available_lane_ids_set.forEach((id: number) => {
        if (!restricted_lane_ids_set.has(id)) {
          available_lane_ids.push(id);
        }
      });

      available_lane_ids.unshift(MapConst.NEW_STRING_NAME);
      if (
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
        MapConst.NULL_STRING_NAME
      ) {
        available_lane_ids.unshift(MapConst.NULL_STRING_NAME);
      }
      return available_lane_ids;
    };

    const getAvailableLaneIdsForStopSignControlLine = (
      feature_index: number,
      feature_info_index: number
    ) => {
      const temp_feature_info =
        testFeatures.features[feature_index].properties?.feature_info_list[
          feature_info_index
        ];
      const line_type = temp_feature_info[MapConst.LINE_TYPE_STRING_NAME];
      let available_lane_ids_set;

      if (line_type === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
        available_lane_ids_set = availableIds.laneIdsForControlLine;
      }

      const available_lane_ids = Array.from(available_lane_ids_set);

      available_lane_ids.unshift(MapConst.NEW_STRING_NAME);

      if (
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
        MapConst.NULL_STRING_NAME
      ) {
        available_lane_ids.unshift(MapConst.NULL_STRING_NAME);
      }
      return available_lane_ids;
    };

    const getAvailableLaneIdsForLineString = (
      feature_index: number,
      feature_info_index: number
    ) => {
      const available_lane_ids: never[] = [];
      const temp_feature_info =
        testFeatures.features[feature_index].properties.feature_info_list[
          feature_info_index
        ];
      const line_type = temp_feature_info[MapConst.LINE_TYPE_STRING_NAME];

      if (line_type === MapConst.NULL_STRING_NAME) {
        return available_lane_ids;
      }
      if (line_type === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
        return getAvailableLaneIdsForStopSignControlLine(
          feature_index,
          feature_info_index
        );
      } else {
        return getAvailableLaneIdsForLaneBoundaryLine(
          feature_index,
          feature_info_index
        );
      }
    };

    const available_lane_ids: Array<string> = getAvailableLaneIdsForLineString(
      feature_index,
      feature_info_index
    );

    const _updateLaneAssocOfControlLine = (
      affectedControlLineId: string | number,
      newLaneAssociation: number
    ) => {
      if (affectedControlLineId === MapConst.NULL_STRING_NAME) {
        return;
      }
      const { controlLines } = mapStructs;
      newLaneAssociation = isNaN(newLaneAssociation)
        ? MapConst.INVALID_ID_NUMBER
        : newLaneAssociation;
      const affectedControlLine = {
        ...controlLines[Number(affectedControlLineId)],
      };

      affectedControlLine["associated_lane_id"] = Number(newLaneAssociation);
      setControlLineAction(affectedControlLine);
    };

    const handleChangeInLineStringLaneAssociation = (
      feature_index: number,
      feature_info_index: number,
      laneId: string
    ) => {
      const temp_feature_info =
        testFeatures.features[feature_index].properties.feature_info_list[
          feature_info_index
        ];
      if (temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] === laneId) {
        return;
      }
      const line_string_feature_id =
        testFeatures.features[feature_index].properties.feature_id;

      // update corresponding map struct of old lane id
      if (
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME ||
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME ||
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.LANE_START_LINE_STRING_NAME ||
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.LANE_TERMINATION_LINE_STRING_NAME
      ) {
        _updateBoundaryLineOfLane(
          temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME],
          MapConst.INVALID_ID_NUMBER
        );
      } else if (
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
      ) {
        // update it directly with new lane id in latter condition
      }

      if (laneId === MapConst.NEW_STRING_NAME) {
        const newLaneId = generateUUID();
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] = newLaneId;
      } else {
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] =
          laneId === "NULL" ? laneId : Number(laneId);
      }

      // update corresponding map struct of new lane id
      if (
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME ||
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME ||
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.LANE_START_LINE_STRING_NAME ||
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
          MapConst.LANE_TERMINATION_LINE_STRING_NAME
      ) {
        _updateBoundaryLineOfLane(
          temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME],
          line_string_feature_id
        );
      } else if (
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
      ) {
        _updateLaneAssocOfControlLine(
          temp_feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME],
          temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME]
        );
      }

      const result_state = update(testFeatures, {
        features: {
          [feature_index]: {
            properties: {
              feature_info_list: {
                [feature_info_index]: { $set: temp_feature_info },
              },
            },
          },
        },
      });
      updateFeatures(result_state);
    };

    return (
      <LaneAssocContainerStyled
        key={
          feature_index +
          "_" +
          feature_info_index +
          "_" +
          MapConst.LANE_ASSOCIATION_STRING_NAME
        }
      >
        <LaneAssocTitleStyled>LANE ASSOC</LaneAssocTitleStyled>
        <LaneAssocDropDownContainerStyled>
          <LineStringFeatureDropdown
            listData={available_lane_ids}
            currentItem={feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME]}
            changeItemAction={(laneId) =>
              handleChangeInLineStringLaneAssociation(
                feature_index,
                feature_info_index,
                laneId
              )
            }
          />
        </LaneAssocDropDownContainerStyled>
      </LaneAssocContainerStyled>
    );
  }
);
