import { FC, memo } from "react";
import * as MapConst from "../../../../constants/map-constants";
import { Col } from "react-bootstrap";
import { RowStyled } from "../../styles";
import * as React from "react";

type ControlLineIdStringProps = {
  feature_index: number;
  feature_info_index: number;
  feature_info: any;
};

export const ControlLineIdString: FC<ControlLineIdStringProps> = memo(
  ({ feature_index, feature_info_index, feature_info }) => {
    return (
      <RowStyled
        key={
          feature_index +
          "_" +
          feature_info_index +
          "_" +
          MapConst.CONTROL_LINE_ID_STRING_NAME
        }
      >
        <Col>
          <b>CONTROL LINE ID</b>
        </Col>
        <Col>
          <b>{feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME]}</b>
        </Col>
      </RowStyled>
    );
  }
);
