import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Feature, GeoJsonProperties, Geometry } from "geojson";

import { closeAnyModal, getModalData } from "../../../store/slices/modals";
import { ModalBackgroundStyled, ModalStyled } from "../styles";
import { verifySemanticMap } from "../../../utils/verifySemanticMap";

import {
  VerifySemanticMapModalStyled,
  HeaderStyled,
  ErrorLineContainerStyled,
  ErrorLineStyled,
  ErrorLineHeaderStyled,
  SuccessLineHeaderStyled,
} from "./styles";
import { fetchPatchMapGeoJson } from "../../../store/slices/projects";
import { GeoJsonType } from "../../../pages/MapEditor/mapEditor";
import { replacer } from "../../../utils";
import { ActionButtonStyled } from "../../GeoJsonSelectTypeModal/styles";
import { getOutputMapStructs } from "../../../store/slices/mapStructs";

export const VerifySemanticMapModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const outputMapStructs = useSelector(getOutputMapStructs);
  const projectId = window.location.pathname.split("/")[2] || "";

  const handleCloseModal = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  const downloadPB = useCallback(() => {
    const fileName = `output_${GeoJsonType.Semantic}.geojson`;
    const file = new Blob(
      [
        JSON.stringify(
          {
            type: "FeatureCollection",
            properties: {
              latLngOrigin: modalData?.properties?.latLngOrigin,
              useLocalCoord: false,
              radius: 0.0005,
            },
            features: modalData?.features,
            map_structs: outputMapStructs,
          },
          replacer
        ),
      ],
      {
        type: "octet/stream",
      }
    );

    dispatch(
      fetchPatchMapGeoJson({
        file,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileName,
        projectId,
        hideToast: true,
      })
    );

    const link = document.createElement("a");
    link.href = `/api/v1/map-projects/${projectId}/semantic_map.pb.bin`;
    document.body.appendChild(link);
    link.download = "semantic_map.pb.bin";
    link.click();
    link.parentNode?.removeChild(link);
  }, [modalData]);

  const errors =
    modalData && modalData.features
      ? verifySemanticMap(
          modalData.features as unknown as Array<
            Feature<Geometry, GeoJsonProperties>
          >
        )
      : [];

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <VerifySemanticMapModalStyled>
        <HeaderStyled>Checking For Any Errors</HeaderStyled>
        {errors && errors.length >= 1 && (
          <ErrorLineContainerStyled>
            <ErrorLineHeaderStyled>Errors found:</ErrorLineHeaderStyled>
            {errors.map((error) => (
              <ErrorLineStyled key={error}>{error}</ErrorLineStyled>
            ))}
          </ErrorLineContainerStyled>
        )}
        {errors && errors.length === 0 && (
          <>
            <SuccessLineHeaderStyled>No issues found</SuccessLineHeaderStyled>
            <ActionButtonStyled onClick={downloadPB}>
              Download .pb.bin
            </ActionButtonStyled>
          </>
        )}
      </VerifySemanticMapModalStyled>
    </ModalStyled>
  );
});
