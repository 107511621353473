import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API from "../../../api";
import { setIsLoading } from "../appState";
import { addNewPointCloud, deletePointCloud, updatePointCloud } from ".";

export const fetchGetPointClouds = createAsyncThunk(
  "pointClouds/fetchGetPointClouds",
  async (_, thunkAPI) => {
    try {
      const response = await API.get("point-clouds");

      return response.data.pointClouds;
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return Promise.reject();
      }
      toast.error("Something went wrong with fetchPointClouds");
    }
    thunkAPI.dispatch(setIsLoading(false));
  }
);

export const fetchPatchPointCloud = createAsyncThunk<
  void,
  { _id: string | undefined; name: string; description: string }
>(
  "pointClouds/fetchPatchPointCloud",
  async ({ _id, name, description }, thunkAPI) => {
    try {
      const response = await API.put(`point-clouds/${_id}`, {
        name,
        description,
      });
      thunkAPI.dispatch(updatePointCloud(response.data));
      toast.success(`Updated Point Cloud: ${name}`);
      thunkAPI.dispatch(setIsLoading(false));
    } catch (error) {
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
      return Promise.reject();
    }
  }
);

export const fetchDeletePointCloud = createAsyncThunk<
  void,
  { _id: string; name?: string }
>("pointClouds/fetchDeletePointCloud", async ({ _id, name }, thunkAPI) => {
  try {
    await API.delete(`point-clouds/${_id}`);
    thunkAPI.dispatch(deletePointCloud(_id));
    if (name) {
      toast.success(`Point Cloud deleted: ${name}`);
    }
    thunkAPI.dispatch(setIsLoading(false));
  } catch (error) {
    const baseError = error as BaseError;
    toast.error(baseError.response.data.detail);
    return Promise.reject();
  }
});

export const fetchPostPointCloudFile = createAsyncThunk<
  void,
  { pointCloud: PointCloud; file: File | null | undefined }
>(
  "pointClouds/fetchPostPointCloudFile",
  async ({ pointCloud, file }, thunkAPI) => {
    try {
      const jwt = window.localStorage.getItem("JWT");
      const fileDataForm = new FormData();
      fileDataForm.append("ply_file", file as Blob);
      thunkAPI.dispatch(setIsLoading(true));
      await API.post(
        `point-clouds/${pointCloud._id}/point-cloud-file`,
        fileDataForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            //TODO fix after changed on backend
            Authorization: jwt || null,
          },
        }
      );
      thunkAPI.dispatch(addNewPointCloud(pointCloud));
      toast.success(`Added Point Cloud: ${pointCloud.name}`);
      thunkAPI.dispatch(setIsLoading(false));
    } catch (error) {
      await thunkAPI.dispatch(fetchDeletePointCloud({ _id: pointCloud._id }));
      const baseError = error as BaseError;
      toast.error("Invalid file type");
      //TODO change error messages on backend
      console.log("Api Error: ", baseError.message);
      thunkAPI.dispatch(setIsLoading(false));
      return Promise.reject();
    }
  }
);

export const fetchPostPointCloud = createAsyncThunk<
  void,
  { name: string; description: string; file: File | null | undefined }
>(
  "pointClouds/fetchPostPointCloud",
  async ({ name, description, file }, thunkAPI) => {
    try {
      const response = await API.post("point-cloud", {
        name,
        description,
        points: "",
      });
      await thunkAPI.dispatch(
        fetchPostPointCloudFile({ pointCloud: response.data, file })
      );
      thunkAPI.dispatch(setIsLoading(false));
      return response.data._id;
    } catch (error) {
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
      thunkAPI.dispatch(setIsLoading(false));
      return Promise.reject();
    }
  }
);
