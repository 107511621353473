import styled from "styled-components";

export const MainPageWrapperStyled = styled.div`
  position: fixed;
  height: calc(100% - 72px);
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
`;
