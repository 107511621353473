import React, { memo } from "react";

import ContextMenu from "./ContextMenu";

import { ReactComponent as LogoSvg } from "./assets/logo.svg";
import {
  HeaderStyled,
  LogoContainerStyled,
  ContentContainerStyled,
} from "./styles";

const Header = memo(() => {
  return (
    <HeaderStyled>
      <ContentContainerStyled>
        <LogoContainerStyled data-test-id="home">
          <LogoSvg />
        </LogoContainerStyled>
      </ContentContainerStyled>
      <ContentContainerStyled>
        <ContextMenu />
      </ContentContainerStyled>
    </HeaderStyled>
  );
});

export default Header;
