import React from "react";
import Button from "react-bootstrap/Button";
import { Trash } from "react-bootstrap-icons";

import {
  FeatureInfo,
  FeaturePropertiesPanel,
} from "./components/FeaturePropertiesPanel";
import * as MapConst from "../../constants/map-constants";
import { generateUUID } from "../../utils";
import { getHtmlColorForFeature } from "../../utils/toolbox";

import { FeatureItemProps } from "./index.d";
import {
  ModalStyled,
  ToolboxCheckboxSpanStyled,
  ToolboxCheckboxStyled,
} from "./styles";

const FeatureItem = ({
  featureType,
  index,
  selectedFeatureIndexes,
  testFeatures,
  isModal,
  featureMenuClick,
  updateFeatures,
  updateSelectedFeatureIndexes,
  deleteFeatureProperty,
}: FeatureItemProps) => {
  const feature = testFeatures.features[index];
  const featureId = feature?.properties?.feature_id || "";

  const handleAddFeatureProperty = (
    e: React.MouseEvent<HTMLButtonElement>,
    featureIndex: number
  ) => {
    const featureType = testFeatures.features?.[featureIndex]?.geometry?.type;

    const feature = testFeatures.features[featureIndex];
    const tempFeatureInfoList = feature.properties?.feature_info_list || [];
    const featureId = feature.properties?.feature_id || generateUUID();

    const featureInfo: FeatureInfo = {};
    if (featureType === "LineString") {
      featureInfo[MapConst.LINE_TYPE_STRING_NAME] = MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.LANE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.CONTROL_LINE_ID_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
    } else if (featureType === "Polygon") {
      featureInfo[MapConst.POLYGON_TYPE_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.LANE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.STOP_SIGN_ID_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.INTERSECTION_ID_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
    }
    tempFeatureInfoList.push(featureInfo);

    const features = [...testFeatures.features];
    features[featureIndex] = {
      ...features[featureIndex],
      properties: {
        feature_id: featureId,
        feature_info_list: tempFeatureInfoList,
      },
    };

    const resultState = {
      ...testFeatures,
      features,
    };
    updateFeatures(resultState);
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    action: string,
    index: number
  ) => {
    e.preventDefault();
    featureMenuClick(action, index);
  };

  return (
    <div key={index}>
      <ToolboxCheckboxStyled
        isHide={isModal}
        type="checkbox"
        checked={selectedFeatureIndexes.includes(index)}
        disabled={!!isModal}
        onChange={() => {
          if (selectedFeatureIndexes.includes(index)) {
            updateSelectedFeatureIndexes(
              selectedFeatureIndexes.filter((e) => e !== index)
            );
          } else {
            updateSelectedFeatureIndexes([...selectedFeatureIndexes, index]);
          }
        }}
      >
        <ToolboxCheckboxSpanStyled
          index={index}
          isSelected={selectedFeatureIndexes.includes(index)}
        >
          {featureId}
          {": "}
          {featureType}
        </ToolboxCheckboxSpanStyled>
        {isModal ? (
          <ModalStyled>
            <Button
              variant="danger"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleClick(e, "delete", index)
              }
            >
              <Trash size={16} title="Delete" />
            </Button>
          </ModalStyled>
        ) : (
          <Button
            style={{
              position: "absolute",
              right: "12px",
              textDecoration: "none",
              color: `${getHtmlColorForFeature(
                index,
                selectedFeatureIndexes.includes(index)
              )}`,
            }}
            variant="link"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              updateSelectedFeatureIndexes([index], {
                index,
                x: e.clientX,
                y: e.clientY,
              });
            }}
          >
            &gt;&gt;
          </Button>
        )}
      </ToolboxCheckboxStyled>
      {selectedFeatureIndexes.includes(index) && (
        <FeaturePropertiesPanel
          deleteFeatureProperty={deleteFeatureProperty}
          handleAddFeatureProperty={handleAddFeatureProperty}
          featureIndex={index}
          testFeatures={testFeatures}
          updateFeatures={updateFeatures}
        />
      )}
    </div>
  );
};

export default FeatureItem;
