import React, { memo, FC } from "react";
import { FileLoaderProps } from "../types";

import { AddPlyFileLoader } from "../AddPlyFileLoader";
import { EditPlyFileLoader } from "../EditPlyFileLoader";

export const PlyFileLoaderController: FC<FileLoaderProps> = memo(
  ({ setFile, file }) =>
    file ? (
      <EditPlyFileLoader setFile={setFile} file={file} />
    ) : (
      <AddPlyFileLoader setFile={setFile} />
    )
);
