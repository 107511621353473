import { configureStore } from "@reduxjs/toolkit";

import user from "./slices/user";
import currentMap from "./slices/currentMap";
import appState from "./slices/appState";
import modals from "./slices/modals";
import pointClouds from "./slices/pointClouds";
import projects from "./slices/projects";
import mapStructs from "./slices/mapStructs";

const store = configureStore({
  reducer: {
    appState,
    user,
    currentMap,
    modals,
    pointClouds,
    projects,
    mapStructs,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
