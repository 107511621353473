import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import jwt_decode from "jwt-decode";
import API from "../../../api";
import {
  setIsLoading,
  setExpirationRefreshToken,
  setExpirationAccessToken,
  resetAppState,
} from ".";
import { fetchGetPointClouds } from "../pointClouds";
import { fetchGetProjects } from "../projects";

type JwtDecode = {
  name: string;
  exp: number;
};

export const getInitialData = createAsyncThunk(
  "appState/fetchCheckAccessToken",
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(fetchGetProjects());
    await thunkAPI.dispatch(fetchGetPointClouds());
    await thunkAPI.dispatch(setIsLoading(false));
  }
);

export const fetchCheckAccessToken = createAsyncThunk(
  "appState/fetchCheckAccessToken",
  async (navigateCallBack: any, thunkAPI) => {
    const currentAccessToken = window.localStorage
      .getItem("JWT")
      ?.replace("Bearer ", "");
    const currentRefreshToken = window.localStorage
      .getItem("JWTrefresh")
      ?.replace("Bearer ", "");
    await thunkAPI.dispatch(
      setExpirationRefreshToken(
        currentRefreshToken
          ? jwt_decode<JwtDecode>(currentRefreshToken).exp * 1000
          : null
      )
    );

    try {
      await API.post("/login/verify/", {
        token: currentAccessToken,
      });
      await API.post("/login/verify/", {
        token: currentRefreshToken,
      });
      await thunkAPI.dispatch(
        setExpirationAccessToken(
          currentAccessToken
            ? jwt_decode<JwtDecode>(currentAccessToken).exp * 1000
            : null
        )
      );
      return;
    } catch (err) {
      try {
        window.localStorage.removeItem("JWT");
        const response = await API.post("/login/refresh/", {
          refresh: currentRefreshToken,
        });
        window.localStorage.setItem("JWT", `Bearer ${response.data.access}`);
        await thunkAPI.dispatch(
          setExpirationRefreshToken(
            response.data.access
              ? jwt_decode<JwtDecode>(response.data.access).exp * 1000
              : null
          )
        );
        return;
      } catch (error) {
        resetAppState();
        window.localStorage.removeItem("JWT");
        window.localStorage.removeItem("JWTrefresh");
        navigateCallBack();
        const baseError = error as BaseError;
        toast.error(baseError.response.data.detail);
      }
    }
  }
);

export const refreshTokenAction = createAsyncThunk(
  "appState/fetchRefreshToken",
  async (navigateCallBack: () => void, thunkAPI) => {
    try {
      const currentRefreshToken = (
        window.localStorage.getItem("JWTrefresh") as string
      ).replace("Bearer ", "");
      const response = await API.post("/login/refresh/", {
        refresh: currentRefreshToken,
      });
      const newRefreshToken = response.data.access;
      await thunkAPI.dispatch(
        setExpirationRefreshToken(
          newRefreshToken
            ? jwt_decode<JwtDecode>(newRefreshToken).exp * 1000
            : null
        )
      );
      window.localStorage.setItem("JWT", `Bearer ${newRefreshToken}`);
    } catch (errorMessage) {
      resetAppState();
      window.localStorage.removeItem("JWT");
      window.localStorage.removeItem("JWTrefresh");
      navigateCallBack();
    }
  }
);
