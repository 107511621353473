import React from "react";

import MapEditor from "../pages/MapEditor";

import { MainPage } from "../pages/MainPage";

export const AuthorizedPages = [
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/mapEditor/:mapProjectId",
    element: <MapEditor />,
  },
];
