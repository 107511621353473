import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

import { sendSentryEvent } from "../../../utils/sentryUtils";
import { toggleModal } from "../../../store/slices/modals";
import {
  ReportModalBackgroundStyled,
  ReportModalContentStyled,
  ReportModalStyled,
  ReportModalTextStyled,
  ReportModalTitleStyled,
} from "./styles";

export const ReportModal = () => {
  const dispatch = useDispatch();
  const [reportText, setReportText] = useState("");

  const clickAction = () => {
    sendSentryEvent(null, "Click report btn", reportText);
    dispatch(toggleModal({ type: null }));
    toast.success("The report has been sent");
  };

  return (
    <ReportModalStyled>
      <ReportModalBackgroundStyled
        onClick={() => dispatch(toggleModal({ type: null }))}
      />
      <ReportModalContentStyled>
        <ReportModalTitleStyled>Report a Problem</ReportModalTitleStyled>
        <ReportModalTextStyled
          value={reportText}
          onChange={(e) => setReportText(e.target.value)}
        />
        <Button onClick={clickAction}>Send a Report</Button>
      </ReportModalContentStyled>
    </ReportModalStyled>
  );
};
