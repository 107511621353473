import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

import { closeAnyModal } from "../../../store/slices/modals";

import {
  VersionsModalLineStyled,
  VersionsModalContentStyled,
  VersionsModalHeaderStyled,
  VersionsModalCopyBtnStyled,
  VersionsModalCloseIconStyled,
} from "./styles";
import { ModalBackgroundStyled, ModalStyled } from "../styles";

const APP_VERSION = window._envK8S_?.APP_VERSION || "No Data";

const VersionsModalLine = ({
  name,
  version,
}: {
  name: string;
  version: string;
}) => {
  return (
    <CopyToClipboard
      text={`${name}: ${version}`}
      onCopy={() => toast.success("Copied to Clipboard!")}
    >
      <VersionsModalLineStyled>
        {name}:<strong>{version}</strong>
      </VersionsModalLineStyled>
    </CopyToClipboard>
  );
};

export const VersionsModal = memo(() => {
  const dispatch = useDispatch();

  const handleCloseAction = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <VersionsModalContentStyled>
        <VersionsModalCloseIconStyled onClick={handleCloseAction} />
        <VersionsModalHeaderStyled>Versions</VersionsModalHeaderStyled>
        <VersionsModalLine name="Frontend" version={APP_VERSION} />
        <CopyToClipboard
          text={`Versions: 
Frontend: ${APP_VERSION}
          `}
          onCopy={() => toast.success("Copied to Clipboard!")}
        >
          <VersionsModalCopyBtnStyled>
            Copy to Clipboard
          </VersionsModalCopyBtnStyled>
        </CopyToClipboard>
      </VersionsModalContentStyled>
    </ModalStyled>
  );
});
