import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slide as Menu } from "react-burger-menu";
import * as React from "react";
import { Button, Accordion, ListGroup } from "react-bootstrap";

import { Lane, StopSign } from "../../map-interface.d";
import { Checkbox } from "../common/Checkbox";

import {
  BmItemTitleStyled,
  CardBodyStyled,
  CardContainerStyled,
  CardHeaderStyled,
  CardStyled,
  EditPlaneButtonStyled,
  EditPlaneToolboxStyled,
  MenuBtnStyled,
  SwitchStyled,
  CancelSelectedLanesButtonStyled,
} from "./styles";
import "./side-menu.css";
import { ResetSpeedLimits } from "./components/ResetSpeedLimits";
import { ExpandedLane } from "./components/ExpandedLane";
import { getMapStructs, resetSpeedLimits } from "../../store/slices/mapStructs";

type SideMenuProps = {
  renderEditPlane: boolean;
  onLaneFocus: (laneIds: Array<number>) => void;
  onStopSignFocus: (stopSignId: number) => void;
  setEditPlaneOpen: (open: boolean) => void;
  adjustEditPlaneElevation: (diff: number) => void;
  adjustEditPlaneRadius: (diff: number) => void;
  onIntersectionFocus: (intersectionId: number) => void;
  removeLane: (laneId: number) => void;
};

export const SideMenu: FC<SideMenuProps> = ({
  renderEditPlane,
  removeLane,
  onLaneFocus,
  onStopSignFocus,
  setEditPlaneOpen,
  adjustEditPlaneElevation,
  adjustEditPlaneRadius,
  onIntersectionFocus,
}) => {
  const dispatch = useDispatch();
  const [openedTab, setOpenedTab] = useState<number | null>(null);
  const [selectedLanes, setSelectedLanes] = useState<Array<any>>([]);
  const mapStructs = useSelector(getMapStructs);

  const selectLane = (event: any, laneId: any) => {
    event.preventDefault();
    let newSelectedLanes = [];

    if (selectedLanes.includes(laneId)) {
      newSelectedLanes = [...selectedLanes.filter((lane) => laneId !== lane)];
    } else {
      newSelectedLanes = [...selectedLanes, laneId];
    }
    setSelectedLanes(newSelectedLanes);
    onLaneFocus(newSelectedLanes);
  };

  const renderEditPlaneToolBox = () => {
    const elev_step = 0.5;
    const radius_step = 0.00005;
    return (
      <EditPlaneToolboxStyled>
        <BmItemTitleStyled>
          Edit Plane
          <SwitchStyled
            name="displayEditPlane"
            value={renderEditPlane ? 1 : 0}
            onChange={() => {
              setEditPlaneOpen(!renderEditPlane);
            }}
          ></SwitchStyled>
        </BmItemTitleStyled>
        <EditPlaneButtonStyled>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              adjustEditPlaneElevation(elev_step);
            }}
          >
            Up
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              adjustEditPlaneElevation(-elev_step);
            }}
          >
            Down
          </Button>
        </EditPlaneButtonStyled>
        <EditPlaneButtonStyled>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              adjustEditPlaneRadius(radius_step);
            }}
          >
            +
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              adjustEditPlaneRadius(-radius_step);
            }}
          >
            -
          </Button>
        </EditPlaneButtonStyled>
      </EditPlaneToolboxStyled>
    );
  };

  const setActiveLane = (lane_id: number) => {
    setOpenedTab(openedTab ? null : lane_id);
  };

  const renderIntersectionProps = (intersection_props: Lane) => {
    let props_list: any = [];
    Object.keys(intersection_props).forEach((prop) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentValue = `${prop}:${intersection_props[prop]}`;
      props_list = props_list.concat(
        <ListGroup.Item key={prop}>{currentValue}</ListGroup.Item>
      );
    });

    return <ListGroup as="ul">{props_list}</ListGroup>;
  };

  const renderIntersectionList = () => {
    const { intersections } = mapStructs;

    if (mapStructs === undefined || intersections === undefined) {
      return null;
    }

    const listItems: any = [];

    Object.values(intersections).forEach((intersection_props: any) => {
      const { intersection_id } = intersection_props;
      listItems.push(
        <CardContainerStyled key={intersection_id}>
          <CardHeaderStyled>
            <MenuBtnStyled
              onClick={() => {
                onIntersectionFocus(intersection_id);
              }}
            >
              {intersection_id}
            </MenuBtnStyled>
          </CardHeaderStyled>
          <Accordion.Collapse eventKey={intersection_id}>
            <CardBodyStyled>
              {renderIntersectionProps(intersection_props)}
            </CardBodyStyled>
          </Accordion.Collapse>
        </CardContainerStyled>
      );
    });

    return (
      <div>
        <BmItemTitleStyled>Intersections</BmItemTitleStyled>
        <Accordion defaultActiveKey="0">{listItems}</Accordion>
      </div>
    );
  };

  const renderStopSignProps = (stop_sign_props: StopSign) => {
    let props_list: any = [];
    Object.keys(stop_sign_props).forEach((prop) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentItem = `${prop}:${stop_sign_props[prop]}`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props_list = props_list.concat(
        <ListGroup.Item key={prop}>{currentItem}</ListGroup.Item>
      );
    });

    return <ListGroup as="ul">{props_list}</ListGroup>;
  };

  const renderStopSignList = () => {
    const { stopSigns } = mapStructs;

    if (mapStructs === undefined || stopSigns === undefined) {
      return null;
    }

    const listItems: any = [];

    Object.values(stopSigns).forEach((stop_sign_props: any) => {
      const { stop_sign_id } = stop_sign_props;
      listItems.push(
        <CardContainerStyled key={stop_sign_id}>
          <CardHeaderStyled>
            <Button
              variant="link"
              size="sm"
              onClick={() => {
                onStopSignFocus(stop_sign_id);
              }}
            >
              {stop_sign_id}
            </Button>
          </CardHeaderStyled>
          <Accordion.Collapse eventKey={stop_sign_id}>
            <CardBodyStyled>
              {renderStopSignProps(stop_sign_props)}
            </CardBodyStyled>
          </Accordion.Collapse>
        </CardContainerStyled>
      );
    });

    return (
      <div>
        <BmItemTitleStyled>Stop Signs</BmItemTitleStyled>
        <Accordion defaultActiveKey="0">{listItems}</Accordion>
      </div>
    );
  };

  const canselSelectedLanes = () => {
    if (selectedLanes.length !== 0) {
      setSelectedLanes([]);
      onLaneFocus([]);
    }
  };

  const { lanes } = mapStructs;

  if (mapStructs === undefined) {
    return null;
  }

  return (
    <Menu right={true} isOpen={true}>
      <div>{renderEditPlaneToolBox()}</div>
      <div>
        {lanes && (
          <div>
            <BmItemTitleStyled>Lane Elements</BmItemTitleStyled>
            {Object.values(lanes).length > 0 && (
              <ResetSpeedLimits
                resetAllSpeedLimits={() => dispatch(resetSpeedLimits())}
              />
            )}
            {selectedLanes.length !== 0 && (
              <CancelSelectedLanesButtonStyled onClick={canselSelectedLanes}>
                Cancel Select
              </CancelSelectedLanesButtonStyled>
            )}
            <Accordion defaultActiveKey="0">
              {Object.values(lanes).map((lane: any) => {
                const { lane_id } = lane;

                return (
                  <CardContainerStyled key={lane_id}>
                    <CardStyled>
                      <MenuBtnStyled
                        onClick={() => {
                          setActiveLane(lane_id);
                        }}
                      >
                        <span>{lane_id}</span>
                        <Checkbox
                          key={lane_id}
                          checked={selectedLanes.includes(lane_id)}
                          onChange={(e: Event) => selectLane(e, lane_id)}
                        />
                      </MenuBtnStyled>
                    </CardStyled>
                    {openedTab === lane_id && (
                      <ExpandedLane lane={lane} removeLane={removeLane} />
                    )}
                  </CardContainerStyled>
                );
              })}
            </Accordion>
          </div>
        )}
      </div>
      <div>{renderIntersectionList()}</div>
      <div>{renderStopSignList()}</div>
    </Menu>
  );
};
