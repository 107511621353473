import styled from "styled-components";

export const LaneAssocDropDownContainerStyled = styled.div`
  display: flex;
  height: 28px;
`;

export const LaneAssocContainerStyled = styled.div`
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
`;

export const LaneAssocTitleStyled = styled.div`
  font-weight: 700;
  width: 100px;
  padding: 0 10px;
`;
