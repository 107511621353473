import React, { FC, memo } from "react";
import { Col, Row } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";

import {
  FeaturePropertiesFeatureInfoStyled,
  FeaturePropertiesTableContainer,
  FeaturePropertiesBodyStyled,
  DeleteFeaturePropertiesStyled,
} from "../../../common/toolbox";
import * as MapConst from "../../../../constants/map-constants";
import { LineStringFeature } from "../../../LineStringFeature/LineStringFeature";
import { PolygonFeature } from "../../../PolygonFeature";
import { FeaturesInterface, Lane } from "../../../../models/map-interface";
import { getAvailableIds, setLane } from "../../../../store/slices/mapStructs";

import { ContainerStyled } from "./styles";

type FeaturePropertiesPanelProps = {
  featureIndex: number;
  testFeatures: FeaturesInterface;
  updateFeatures: (temp_features: any) => void;
  handleAddFeatureProperty: (
    e: React.MouseEvent<HTMLButtonElement>,
    featureIndex: number
  ) => void;
  deleteFeatureProperty: (
    featureIndex: number,
    featureInfoIndex: number
  ) => void;
};

export type FeatureInfo = { [key: string]: string | number };

export const FeaturePropertiesPanel: FC<FeaturePropertiesPanelProps> = memo(
  ({
    featureIndex,
    testFeatures,
    handleAddFeatureProperty,
    updateFeatures,
    deleteFeatureProperty,
  }) => {
    const dispatch = useDispatch();
    const availableIds = useSelector(getAvailableIds);
    const geoJsonProperties = testFeatures.features[featureIndex].properties;
    const isHasProperties = Object.keys(geoJsonProperties || {}).length > 0;
    const featureInfoList =
      isHasProperties && (geoJsonProperties?.feature_info_list || []);
    const featureId =
      testFeatures.features[featureIndex].properties?.feature_id;

    const addNewLaneToMapStructs = (laneId: number) => {
      const newLane: Lane = {
        lane_id: laneId,
        left_boundary_line_id: MapConst.INVALID_ID_NUMBER,
        right_boundary_line_id: MapConst.INVALID_ID_NUMBER,
        start_line_id: MapConst.INVALID_ID_NUMBER,
        termination_line_id: MapConst.INVALID_ID_NUMBER,
      };
      dispatch(setLane(newLane));
    };

    return (
      <FeaturePropertiesTableContainer>
        {isHasProperties && (
          <>
            {[
              <ContainerStyled
                key={featureIndex + "_" + MapConst.FEATURE_ID_STRING_NAME}
              >
                <Row>
                  <Col xs={6}>
                    <b>FEATURE ID</b>
                  </Col>
                  <Col xs={6}>
                    <b>{featureId}</b>
                  </Col>
                </Row>
              </ContainerStyled>,
              featureInfoList.map(
                (featureInfoItem: FeatureInfo, featureInfoIndex: number) => (
                  <FeaturePropertiesBodyStyled
                    key={
                      featureIndex + "_" + featureInfoIndex + "_feature_info"
                    }
                  >
                    <FeaturePropertiesFeatureInfoStyled className="feature-property-info">
                      {MapConst.LINE_TYPE_STRING_NAME in featureInfoItem ? (
                        <LineStringFeature
                          testFeatures={testFeatures}
                          availableIds={availableIds}
                          updateFeatures={updateFeatures}
                          feature_info={featureInfoItem}
                          feature_index={featureIndex}
                          feature_info_index={featureInfoIndex}
                        />
                      ) : (
                        <PolygonFeature
                          availableIds={availableIds}
                          updateFeatures={updateFeatures}
                          testFeatures={testFeatures}
                          feature_info={featureInfoItem}
                          feature_index={featureIndex}
                          feature_info_index={featureInfoIndex}
                          addNewLaneToMapStructs={addNewLaneToMapStructs}
                        />
                      )}
                    </FeaturePropertiesFeatureInfoStyled>
                    <DeleteFeaturePropertiesStyled
                      onClick={() =>
                        deleteFeatureProperty(featureIndex, featureInfoIndex)
                      }
                    >
                      <XCircleFill size={24} color={"red"} />
                    </DeleteFeaturePropertiesStyled>
                  </FeaturePropertiesBodyStyled>
                )
              ),
            ]}
          </>
        )}

        <button onClick={(e) => handleAddFeatureProperty(e, featureIndex)}>
          Add New Feature Properties
        </button>
      </FeaturePropertiesTableContainer>
    );
  }
);
