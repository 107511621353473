import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { DataStatus } from "../../types";

export const getProjects = (state: RootState): Project[] => state.projects.data;
const getProjectsStatus = (state: RootState) => state.projects.status;

export const getIsProjectsLoaded = createSelector(
  getProjectsStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsProjectsLoadingError = createSelector(
  getProjectsStatus,
  (status) => status === DataStatus.failed
);
