/* eslint-disable @typescript-eslint/no-unused-vars */
import { toWgs84 } from "@turf/projection";
import polygonzine from "@turf/line-to-polygon";
import pointInPolygon from "@turf/boolean-point-in-polygon";
import * as turf from "@turf/helpers";
import distance from "@turf/distance";
import { Point } from "bezier-js";
import { cloneDeep } from "lodash";
import UUID from "uuid-int";

import { FeaturesInterface, Lane } from "../models/map-interface.d";
import * as MapConst from "../constants/map-constants";
import { AvailableIds } from "../map-interface.d";
import { FEATURE_COLORS } from "../constants/nebula";

interface SnappingPointInterface {
  point: GeoJSON.Position;
  indexes: number[];
}

export const isClientRendered = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export function replacer(key: any, value: any) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const originalObject = this[key];
  if (originalObject instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(originalObject.entries()), // or with spread: value: [...originalObject]
    };
  } else if (originalObject instanceof Set) {
    return {
      dataType: "Set",
      value: Array.from(originalObject),
    };
  } else {
    return value;
  }
}

export function reviver(key: any, value: any) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    } else if (value.dataType === "Set") {
      return new Set(value.value);
    }
  }
  return value;
}

export const hex2rgb = (hex: string) => {
  const value = parseInt(hex, 16);
  return [16, 8, 0].map((shift) => ((value >> shift) & 0xff) / 255);
};

const getPositionCount = (geometry: {
  type: any;
  coordinates: any;
}): number => {
  const flatMap = (
    f: { (x: any): any; (x: any): any; (y: any): any; (arg0: any): any },
    arr: any[]
  ) => arr.reduce((x: any, y: any) => [...x, ...f(y)], []);

  const { type, coordinates } = geometry;
  switch (type) {
    case "Point":
      return 1;
    case "LineString":
    case "MultiPoint":
      return coordinates.length;
    case "Polygon":
    case "MultiLineString":
      return flatMap((x: any) => x, coordinates).length;
    case "MultiPolygon":
      return flatMap((x: any) => flatMap((y: any) => y, x), coordinates).length;
    default:
      throw Error(`Unknown geometry type: ${type}`);
  }
};

export const featuresToInfoString = (featureCollection: any): string => {
  const info = featureCollection.features.map(
    (feature: { geometry: { type: any } }) =>
      `${feature.geometry.type}(${getPositionCount(feature.geometry as any)})`
  );

  return JSON.stringify(info);
};

export const pointToCoor = (p: Point) => toWgs84([p.x, p.y]);

const getLineCoordinates = (
  id: number,
  features: GeoJSON.Feature<GeoJSON.LineString>[],
  points: GeoJSON.Position[]
) => {
  if (id !== MapConst.INVALID_ID_NUMBER) {
    const feature = features.find((f) => f.properties?.feature_id === id);

    if (feature) {
      if (points.length === 0) {
        return feature.geometry.coordinates;
      }

      // Check the closest point to the current line and reverse it if is backwards
      if (
        distance(points[points.length - 1], feature.geometry.coordinates[0]) <
        distance(
          points[points.length - 1],
          feature.geometry.coordinates[feature.geometry.coordinates.length - 1]
        )
      ) {
        return feature?.geometry?.coordinates;
      } else {
        return feature?.geometry?.coordinates.reverse() || [];
      }
    }
  }
  return [];
};

export const makePolygon = (
  lane: Lane,
  features: GeoJSON.Feature<GeoJSON.LineString>[]
) => {
  const points: GeoJSON.Position[] = [];

  points.push(
    ...getLineCoordinates(lane.right_boundary_line_id, features, points)!
  );
  points.push(...getLineCoordinates(lane.start_line_id, features, points)!);
  points.push(
    ...getLineCoordinates(lane.left_boundary_line_id, features, points)!
  );
  points.push(
    ...getLineCoordinates(lane.termination_line_id, features, points)!
  );
  const line = turf.lineString(points);

  return {
    id: lane.lane_id,
    line,
    polygon: polygonzine(line) as GeoJSON.Feature<GeoJSON.Polygon>,
  };
};

/**
 * Determine if the sended coordinate is inside a lane segment
 * @returns The coordinate is inside a lane segment
 */
export const onLaneSegment = (
  { coordinate }: { coordinate: number[] },
  lanes: Record<number, Lane>,
  features: GeoJSON.Feature<GeoJSON.LineString>[]
) => {
  for (const l of Object.values(lanes)) {
    const lane = makePolygon(l, features);
    const p = turf.point(coordinate);

    if (pointInPolygon(p, lane.polygon)) {
      return lane.id;
    }
  }

  return "";
};

const _featureIdtoIndex = (
  features: GeoJSON.Feature<GeoJSON.Geometry>[] = [],
  feature_id: number
) => {
  for (let i = 0; i < features.length; i++) {
    if (String(features[i].properties?.feature_id) === String(feature_id))
      return i;
  }
  return -1;
};

const controlLineIdtoIndex = (
  features: GeoJSON.Feature<GeoJSON.Geometry>[] = [],
  controlLineId: number
) => {
  for (let i = 0; i < features.length; i++) {
    const featureInfoList = features[i].properties?.feature_info_list;
    if (
      featureInfoList &&
      featureInfoList[0] &&
      featureInfoList[0].control_line_id === controlLineId
    ) {
      return i;
    }
  }
  return -1;
};

const stopSignIdtoIndex = (
  features: GeoJSON.Feature<GeoJSON.Geometry>[] = [],
  stopSignId: number
) => {
  for (let i = 0; i < features.length; i++) {
    const featureInfoList = features[i].properties?.feature_info_list;
    if (
      featureInfoList &&
      featureInfoList[0] &&
      featureInfoList[0].stop_sign_id === stopSignId
    ) {
      return i;
    }
  }
  return -1;
};

export const getLaneFeatures = (
  features: GeoJSON.Feature<GeoJSON.Geometry>[] = [],
  lane_info?: Lane,
  controlLineId?: number,
  stopSignId?: number
) => {
  if (!lane_info) return [];
  let lane_related_features: number[] = [];
  if (lane_info.left_boundary_line_id !== MapConst.INVALID_ID_NUMBER) {
    lane_related_features = lane_related_features.concat(
      _featureIdtoIndex(features, lane_info.left_boundary_line_id)
    );
  }
  if (lane_info.right_boundary_line_id !== MapConst.INVALID_ID_NUMBER) {
    lane_related_features = lane_related_features.concat(
      _featureIdtoIndex(features, lane_info.right_boundary_line_id)
    );
  }
  if (lane_info.start_line_id !== MapConst.INVALID_ID_NUMBER) {
    lane_related_features = lane_related_features.concat(
      _featureIdtoIndex(features, lane_info.start_line_id)
    );
  }
  if (lane_info.termination_line_id !== MapConst.INVALID_ID_NUMBER) {
    lane_related_features = lane_related_features.concat(
      _featureIdtoIndex(features, lane_info.termination_line_id)
    );
  }
  if (controlLineId) {
    lane_related_features = lane_related_features.concat(
      controlLineIdtoIndex(features, controlLineId)
    );
  }
  if (stopSignId) {
    lane_related_features = lane_related_features.concat(
      stopSignIdtoIndex(features, stopSignId)
    );
  }

  return lane_related_features.filter((featureIndex) => featureIndex !== -1);
};

const getPickedEditHandles = (picks: any): any[] => {
  const handles =
    (picks &&
      picks
        .filter(
          (pick: any) =>
            pick.isGuide && pick.object.properties.guideType === "editHandle"
        )
        .map((pick: any) => pick.object)) ||
    [];

  return handles;
};

export const getPickedEditHandle = (picks: any): any | null | undefined => {
  const handles = getPickedEditHandles(picks);
  return handles.length ? handles[0] : null;
};

const snapPoint = (origin: GeoJSON.Position, destination: GeoJSON.Position) => {
  let result = origin;
  if (!destination || destination.length > 2) return result;

  const diffPoints =
    origin[0] !== destination[0] || origin[1] !== destination[1];

  if (
    diffPoints &&
    destination.slice &&
    distance(origin, destination.slice(0, 2)) <= MapConst.SNAP_MIN_DISTANCE
  ) {
    result = destination;
  }

  return result;
};

export const snapFeature = (
  featureIndexes: number[],
  updatedData: FeaturesInterface,
  deltaIndex = 0
): FeaturesInterface => {
  const data = cloneDeep(updatedData);

  featureIndexes.forEach((index) => {
    const feature = data.features[index];

    // Snapping
    if (
      feature.geometry.type === "LineString" &&
      feature.properties &&
      !feature.properties.type
    ) {
      const line = feature.geometry as GeoJSON.LineString;

      data.features.forEach((f, i) => {
        if (i !== index - deltaIndex) {
          const l = f.geometry as GeoJSON.LineString;
          // Check first and last point
          line.coordinates[0] = snapPoint(
            line.coordinates[0],
            l.coordinates[0]
          );
          line.coordinates[0] = snapPoint(
            line.coordinates[0],
            l.coordinates[l.coordinates.length - 1]
          );
          line.coordinates[line.coordinates.length - 1] = snapPoint(
            line.coordinates[line.coordinates.length - 1],
            l.coordinates[0]
          );
          line.coordinates[line.coordinates.length - 1] = snapPoint(
            line.coordinates[line.coordinates.length - 1],
            l.coordinates[l.coordinates.length - 1]
          );
        }
      });
    }
  });

  return data;
};

export const getNewFeatureId = (features: GeoJSON.Feature[]) => {
  let maxFeatureId = features[0]?.properties?.feature_id;
  features.forEach((feature) => {
    if (feature.properties && feature.properties.feature_id > maxFeatureId) {
      maxFeatureId = feature.properties.feature_id;
    }
  });
  return maxFeatureId + 1;
};

const arePositionEqual = (
  position1: GeoJSON.Position,
  position2: GeoJSON.Position
) => {
  const [x1, y1] = position1;
  const [x2, y2] = position2;

  return x1 === x2 && y1 === y2;
};

const getSnappingPoint = (
  feature1: GeoJSON.Feature,
  feature2: GeoJSON.Feature
): SnappingPointInterface | null => {
  const line1 = feature1.geometry as GeoJSON.LineString;
  const line2 = feature2.geometry as GeoJSON.LineString;
  const line1StartPoint = line1.coordinates[0];
  const line1EndPoint = line1.coordinates[line1.coordinates.length - 1];
  const line2StartPoint = line2.coordinates[0];
  const line2EndPoint = line2.coordinates[line2.coordinates.length - 1];

  // TODO: return adjacent point too to calculate
  if (arePositionEqual(line1StartPoint, line2StartPoint)) {
    return { point: line1StartPoint, indexes: [0, 0] };
  }

  if (arePositionEqual(line1EndPoint, line2StartPoint)) {
    return {
      point: line1EndPoint,
      indexes: [line1.coordinates.length - 1, 0],
    };
  }

  if (arePositionEqual(line1StartPoint, line2EndPoint)) {
    return {
      point: line1StartPoint,
      indexes: [0, line1.coordinates.length - 1],
    };
  }

  if (arePositionEqual(line1EndPoint, line2EndPoint)) {
    return {
      point: line1EndPoint,
      indexes: [line1.coordinates.length - 1, line1.coordinates.length - 1],
    };
  }

  return null;
};

const getTangentLineConstraintCoords = (
  features: GeoJSON.Feature[],
  snappingPoint: SnappingPointInterface,
  selectedFeatureIndexes: number[]
) => {
  // TODO: Implement Tangent Line Constraint Logic
};

export const tangentLineConstraint = (
  features: GeoJSON.Feature[],
  selectedFeatureIndexes: number[],
  editContext: any
) => {
  let updatedSelectedFeatureIndexes = selectedFeatureIndexes;
  const { featureIndex } = editContext;

  if (updatedSelectedFeatureIndexes.length < MapConst.TANGENT_LINE_LIMIT) {
    updatedSelectedFeatureIndexes = [
      ...selectedFeatureIndexes,
      ...featureIndex,
    ];
  } else {
    throw new Error("Too many lines. Pick only two");
  }

  if (updatedSelectedFeatureIndexes.length === MapConst.TANGENT_LINE_LIMIT) {
    const [first, last] = updatedSelectedFeatureIndexes;
    const snappingPoint = getSnappingPoint(features[first], features[last]);

    if (snappingPoint) {
      getTangentLineConstraintCoords(
        features,
        snappingPoint,
        updatedSelectedFeatureIndexes
      );
    } else {
      throw new Error("Lines don't have common snapping point");
    }
    updatedSelectedFeatureIndexes = [];
  }

  return updatedSelectedFeatureIndexes;
};

export const getFilteredAvalibleIds = (
  availableIds: any,
  laneId: number
): AvailableIds => {
  Object.keys(availableIds).forEach((key) => {
    availableIds[key].delete(laneId);
  });
  return availableIds;
};

export const getFilteredFeatures = (
  features: Array<GeoJSON.Feature>,
  laneId: number
): Array<GeoJSON.Feature> => {
  const filteredFeatures: Array<GeoJSON.Feature> = [];
  features.forEach((feature) => {
    filteredFeatures.push({
      ...feature,
      properties: {
        ...feature.properties,
        feature_info_list: feature.properties?.feature_info_list.filter(
          (assotiation: any) =>
            Number(assotiation.lane_association) !== Number(laneId)
        ),
      },
    });
  });

  return filteredFeatures;
};

export const getDeckColorForFeature = (
  index: number,
  bright: number,
  alpha: number
): RGBAColor => {
  const length = FEATURE_COLORS.length;
  const color = FEATURE_COLORS[index % length].map(
    (c: any) => c * bright * 255
  );

  return [...color, alpha * 255] as any;
};

const generator = UUID(1);

export const generateUUID = (): number => {
  let newId = generator.uuid() >> 64;

  if (newId < 0) {
    newId *= -1;
  }

  return newId;
};

export const arrayToObjectByField = <T extends Record<string, unknown>>(
  currentArray: T[],
  fieldName: keyof T = "id"
): Record<string, T> => {
  return currentArray.reduce<Record<string, T>>((acc, arrayItem) => {
    const currentItemId = arrayItem[fieldName];

    if (currentItemId) {
      acc[currentItemId as string] = arrayItem;
    }
    return acc;
  }, {});
};
