import React, { memo, FC, useCallback, useRef } from "react";

import { DragAndDropFileHOC } from "../DragAndDropFileHOC";
import { FileLoaderProps } from "../types";

import {
  FileLoaderBodyStyled,
  TextHelperStyled,
  FileInputStyled,
  TextFormatLoaderStyled,
  FilePickerStyled,
} from "./styles";

export const AddPlyFileLoader: FC<FileLoaderProps> = memo(({ setFile }) => {
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleFilePicker = useCallback(() => {
    fileInputRef.current.click();
  }, []);
  const handlerLoadFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setFile(event.target.files?.[0] || null);
    },
    []
  );

  return (
    <DragAndDropFileHOC setFile={setFile}>
      {(isDragActive) => (
        <FileLoaderBodyStyled isDragActive={isDragActive}>
          <TextHelperStyled>
            Drop your file here, or
            <FilePickerStyled onClick={handleFilePicker}>
              browse
            </FilePickerStyled>
            <FileInputStyled
              ref={fileInputRef}
              onChange={handlerLoadFile}
              type="file"
              accept=".ply"
            />
          </TextHelperStyled>
          <TextFormatLoaderStyled>Supports: *.ply</TextFormatLoaderStyled>
        </FileLoaderBodyStyled>
      )}
    </DragAndDropFileHOC>
  );
});
