//TODO change after backend part
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API from "../../../api";
import { setIsLoading } from "../appState";

export const fetchGetLogin = createAsyncThunk(
  "auth/fetchGetLogin",
  async (
    { email, password }: { email: string; password: string },
    thunkAPI
  ) => {
    try {
      const response = await API.post("/login/", {
        email,
        password,
      });
      if (response.data) {
        window.localStorage.setItem("JWT", `Bearer ${response.data.access}`);
        window.localStorage.setItem(
          "JWTrefresh",
          `Bearer ${response.data.refresh}`
        );
      }
      thunkAPI.dispatch(setIsLoading(false));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
    }
  }
);

// export const verifyMFA = createAsyncThunk(
//   "auth/verifyMFA",
//   async ({ id, token, code }: any, thunkAPI) => {
//     try {
//       const response = await API.post("/login", {
//         mfa: {
//           id,
//           token,
//           code,
//         },
//       });
//       thunkAPI.dispatch(setIsLoading(false));
//       return response.data;
//     } catch (error: any) {
//       let errorMessage = "An error occured with multi-factor authentication.";
//       try {
//         const message = error.response.data.detail.message;
//         if (message) {
//           errorMessage = message;
//         }
//       } catch (e) {
//         console.error("There was an error in the response message.");
//       }
//       thunkAPI.dispatch(setIsLoading(false));
//       return new Error(errorMessage);
//     }
//   }
// );
