import { Container } from "react-bootstrap";
import styled from "styled-components";

export const ContainerStyled = styled(Container)`
  width: 100%;
  padding: 0px 24px;

  .col-6 {
    min-width: 150px !important;
  }
`;
