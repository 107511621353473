import { DropdownButton, Row } from "react-bootstrap";
import styled from "styled-components";

export const DropdownButtonStyled = styled(DropdownButton)`
  padding: 0;
`;

export const RowStyled = styled(Row)`
  margin: 0 0 8px 0;
`;
